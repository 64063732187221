import styles from './styles.module.css';
import classNames from 'classnames';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import React, { CSSProperties, useState } from 'react';

export interface CustomButtonProps {
	content: string;
	variant?: 'primary' | 'secondary';
	disabled?: boolean;
	inlineStyle?: CSSProperties;
	className?: string;
	iconLeft?: JSX.Element;
	iconRight?: JSX.Element;
	onClick?: () => any;
	linkTo?: string;
	linkState?: any;
	ref?: any;
	name?: string;
	required?: boolean;
	type?: 'submit' | 'reset' | 'button' | 'file';
	accept?: string;
}

const fileUploadButton = (props: CustomButtonProps, ref: any) => {
	const [isUploaded, setIsUploaded] = useState(false);
	const {
		content,
		variant,
		inlineStyle,
		className,
		iconLeft,
		iconRight,
		name,
		accept,
		required,
	} = props;
	// swap variant when uploaded
	const appliedVariant = isUploaded ? (variant === 'primary' ? 'secondary' : 'primary') : variant;

	const classesApplied = classNames(
		styles.button,
		{ [styles.buttonPrimary]: appliedVariant === 'primary' },
		{ [styles.buttonSecondary]: appliedVariant === 'secondary' },
		{ [styles.buttonWithIconLeft]: iconLeft },
		{ [styles.buttonWithIconRight]: iconRight },
		className
	);

	const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files?.length && event.target.files.length > 0) {
			setIsUploaded(true);
		} else if (isUploaded === true) {
			setIsUploaded(false);
		}
	};

	const allContent = (
		<>
			{iconLeft ? iconLeft : null}
			{content}
			{iconRight ? iconRight : null}
		</>
	);

	return (
		<>
			<input
				name={name}
				id={name}
				type="file"
				accept={accept}
				ref={ref}
				onChange={onChange}
				className={styles.inputFile}
			/>
			<label htmlFor={name} className={classesApplied} style={inlineStyle}>
				{allContent}
				{required ? ' *' : ''}
			</label>
		</>
	);
};

export const CustomButton = React.forwardRef((props: CustomButtonProps, ref: any) => {
	const {
		content,
		variant,
		disabled,
		inlineStyle,
		className,
		iconLeft,
		iconRight,
		onClick,
		type,
		linkTo,
		linkState,
		name,
		required,
	} = props;

	const classesApplied = classNames(
		styles.button,
		{ [styles.buttonPrimary]: variant === 'primary' },
		{ [styles.buttonSecondary]: variant === 'secondary' },
		{ [styles.buttonWithIconLeft]: iconLeft },
		{ [styles.buttonWithIconRight]: iconRight },
		{ [styles.buttonLink]: linkTo },
		className
	);

	const allContent = (
		<>
			{iconLeft ? iconLeft : null}
			{content}
			{iconRight ? iconRight : null}
		</>
	);

	const button = (
		<button
			name={name}
			type={type === 'file' ? 'button' : type}
			className={classesApplied}
			style={inlineStyle}
			onClick={onClick}
			disabled={disabled}
			ref={ref}
		>
			{allContent}
		</button>
	);

	const link = (
		<AnchorLink
			className={classesApplied}
			gatsbyLinkProps={{ style: inlineStyle, state: linkState }}
			to={linkTo || '/'}
		>
			{allContent}
			{required ? ' *' : ''}
		</AnchorLink>
	);

	if (linkTo && type !== 'file') {
		return link;
	} else if (type === 'file') {
		return fileUploadButton(props, ref);
	}
	return button;
});
