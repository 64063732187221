import styles from './styles.module.css';
import React, { CSSProperties } from 'react';

interface Props {
	color?: string;
	scale?: number;
	style?: CSSProperties;
}

export const LockIcon = (props: Props) => {
	const { color, scale, style } = props;
	const fill = color ? color : 'currentColor';
	const width = 30 * (scale ? scale : 1);
	const height = 42 * (scale ? scale : 1);
	return (
		<span className={styles.iconWrapper} style={style}>
			<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 30 42">
				<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
					<g fill={fill} transform="translate(-517 -21)">
						<path d="M542.924 35.047h-1.236c0-2.191-.046-5.333-.138-5.857-.778-5.047-4.442-8.19-9.527-8.19-5.039 0-8.657 3.095-9.528 8.046-.092.62-.138 3.761-.183 6.001h-1.236c-2.244 0-4.076 2.143-4.076 4.81V58.19c0 2.667 1.832 4.81 4.076 4.81h21.848c2.244 0 4.076-2.143 4.076-4.81V39.857c0-2.619-1.832-4.81-4.076-4.81zm-7.328 20.857h-7.147l2.337-6.096c-1.374-.524-2.337-1.904-2.337-3.476 0-2.048 1.603-3.715 3.573-3.715s3.574 1.667 3.574 3.715c0 1.618-.963 2.952-2.337 3.476l2.337 6.096zm-8.932-20.857c.046-2.048.046-4.713.138-5.286.64-3.761 3.573-4.286 5.22-4.286 1.695 0 4.628.571 5.268 4.38.046.525.091 3.144.091 5.19l-10.717.002z"></path>
					</g>
				</g>
			</svg>
		</span>
	);
};
