import styles from './styles.module.css';
import React, { CSSProperties } from 'react';

interface Props {
	direction?: 'right' | 'left';
	scale?: number;
	style?: CSSProperties;
}

export const CarouselArrow = (props: Props) => {
	const { direction, scale, style } = props;
	const width = 64 * (scale ? scale : 1);
	const height = 64 * (scale ? scale : 1);
	const rightIcon = (
		<span className={styles.iconWrapper} style={style}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				xmlnsXlink="http://www.w3.org/1999/xlink"
				width={width}
				height={height}
				viewBox="0 0 64 64"
			>
				<defs>
					<filter
						id="filter-1"
						width="161.5%"
						height="161.5%"
						x="-30.8%"
						y="-26.9%"
						filterUnits="objectBoundingBox"
					>
						<feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
						<feGaussianBlur
							in="shadowOffsetOuter1"
							result="shadowBlurOuter1"
							stdDeviation="2"
						></feGaussianBlur>
						<feColorMatrix
							in="shadowBlurOuter1"
							result="shadowMatrixOuter1"
							values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.387292395 0"
						></feColorMatrix>
						<feMerge>
							<feMergeNode in="shadowMatrixOuter1"></feMergeNode>
							<feMergeNode in="SourceGraphic"></feMergeNode>
						</feMerge>
					</filter>
					<circle id="path-2" cx="26" cy="26" r="26"></circle>
					<filter
						id="filter-3"
						width="126.9%"
						height="126.9%"
						x="-13.5%"
						y="-9.6%"
						filterUnits="objectBoundingBox"
					>
						<feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
						<feGaussianBlur
							in="shadowOffsetOuter1"
							result="shadowBlurOuter1"
							stdDeviation="2"
						></feGaussianBlur>
						<feColorMatrix
							in="shadowBlurOuter1"
							values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.144340035 0"
						></feColorMatrix>
					</filter>
				</defs>
				<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
					<g transform="translate(-400 -236)">
						<g filter="url(#filter-1)" transform="translate(406 238)">
							<g>
								<g>
									<use fill="#000" filter="url(#filter-3)" xlinkHref="#path-2"></use>
									<use fill="#FFF" xlinkHref="#path-2"></use>
								</g>
								<path fill="#52D07A" d="M14 25L32 25 32 27 14 27z"></path>
								<path
									fill="#52D07A"
									d="M27.5562446 33L26 31.4322155 31.8896175 25.5 26 19.5677845 27.5562446 18 35 25.5000269z"
								></path>
							</g>
						</g>
					</g>
				</g>
			</svg>
		</span>
	);

	const leftIcon = (
		<span className={styles.iconWrapper} style={style}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				xmlnsXlink="http://www.w3.org/1999/xlink"
				width={width}
				height={height}
				viewBox="0 0 64 64"
			>
				<defs>
					<filter
						id="filter-1"
						width="161.5%"
						height="161.5%"
						x="-30.8%"
						y="-26.9%"
						filterUnits="objectBoundingBox"
					>
						<feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
						<feGaussianBlur
							in="shadowOffsetOuter1"
							result="shadowBlurOuter1"
							stdDeviation="2"
						></feGaussianBlur>
						<feColorMatrix
							in="shadowBlurOuter1"
							result="shadowMatrixOuter1"
							values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.387292395 0"
						></feColorMatrix>
						<feMerge>
							<feMergeNode in="shadowMatrixOuter1"></feMergeNode>
							<feMergeNode in="SourceGraphic"></feMergeNode>
						</feMerge>
					</filter>
					<circle id="path-2" cx="26" cy="26" r="26"></circle>
					<filter
						id="filter-3"
						width="126.9%"
						height="126.9%"
						x="-13.5%"
						y="-9.6%"
						filterUnits="objectBoundingBox"
					>
						<feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
						<feGaussianBlur
							in="shadowOffsetOuter1"
							result="shadowBlurOuter1"
							stdDeviation="2"
						></feGaussianBlur>
						<feColorMatrix
							in="shadowBlurOuter1"
							values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.144340035 0"
						></feColorMatrix>
					</filter>
				</defs>
				<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
					<g transform="translate(-490 -236)">
						<g transform="translate(496 238)">
							<g filter="url(#filter-1)" transform="matrix(-1 0 0 1 52 0)">
								<g>
									<g>
										<use fill="#000" filter="url(#filter-3)" xlinkHref="#path-2"></use>
										<use fill="#FFF" xlinkHref="#path-2"></use>
									</g>
									<path fill="#52D07A" d="M14 25L32 25 32 27 14 27z"></path>
									<path
										fill="#52D07A"
										d="M27.5562446 33L26 31.4322155 31.8896175 25.5 26 19.5677845 27.5562446 18 35 25.5000269z"
									></path>
								</g>
							</g>
						</g>
					</g>
				</g>
			</svg>
		</span>
	);

	return direction === 'left' ? leftIcon : rightIcon;
};
