import React, { useState } from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';

import styles from './styles.module.css';
import { CaretIcon } from 'src/icons/caret';
import { LockIcon } from 'src/icons/lock';
import { MainLogo } from 'src/icons/mainLogo';
import { NavDrawerIcon } from 'src/icons/navDrawer';
import { SearchIcon } from 'src/icons/search';
import {
	defaultIndustryPageSections,
	defaultAboutPageSections,
	defaultResearchAndDevelopmentPageSections,
	defaultProgramsAndServicesPageSections,
	defaultWelfarePageSections,
	defaultMediaPageSections,
} from 'src/utils/common';
import { PageNames } from 'src/utils/common';
import { LinkHelper } from 'src/utils/link-helper';

export type NavLink = {
	url: string;
	text: string;
};

type StaticTopNavItemProps = {
	id: string;
	pageUrl: string;
	label: string;
	links: NavLink[];
};

const StaticVisibleTopNavItem = (props: StaticTopNavItemProps) => {
	const { id, pageUrl, label, links } = props;
	return (
		<div className={styles.staticNavItemContainer} id={id}>
			<Link
				className={styles.staticNavItem}
				to={pageUrl}
				partiallyActive
				activeClassName={styles.staticNavItemActive}
			>
				<span>{label}</span>
				<CaretIcon direction="down" paddingPx={{ left: 10 }} />
			</Link>
			<div className={styles.staticNavSubitemsContainer}>
				{links.map((link) => {
					return (
						<Link className={styles.staticNavSubitem} key={link.url} to={link.url}>
							{link.text}
						</Link>
					);
				})}
			</div>
		</div>
	);
};

interface SetIsDrawerOpen {
	setIsDrawerOpen: (isOpen: boolean) => void;
}

const StaticTopNavDrawerItem = (props: StaticTopNavItemProps & SetIsDrawerOpen) => {
	const [showSubitems, setShowSubitems] = useState(false);
	const { id, label, pageUrl, links, setIsDrawerOpen } = props;
	let isActive = false;
	if (typeof window !== 'undefined') {
		isActive = window.location.href.includes(pageUrl);
	}
	const itemClass = classNames(styles.staticNavItemDrawer, {
		[styles.staticNavItemDrawerActive]: isActive,
	});
	const onLinkClick = () => {
		setShowSubitems(false);
		setIsDrawerOpen(false);
	};

	return (
		<div id={id} className={styles.staticNavItemDrawerContainer}>
			<div className={itemClass} onClick={() => setShowSubitems((prevVal) => !prevVal)}>
				<span>{label}</span>
				<CaretIcon direction="down" paddingPx={{ left: 10 }} />
			</div>
			{showSubitems && (
				<div className={styles.staticNavItemDrawerSubitems}>
					{links.map((link) => {
						return (
							<Link
								className={styles.staticNavSubitem}
								key={link.url}
								to={link.url}
								onClick={onLinkClick}
							>
								{link.text}
							</Link>
						);
					})}
				</div>
			)}
		</div>
	);
};

const getDefaultLinks = (
	page: PageNames,
	defaultPageSections: { [heading: string]: string }
): NavLink[] => {
	return Object.entries(defaultPageSections).map(([_, heading]) => ({
		url: `/${LinkHelper.getPageBaseUrl(page)}#${LinkHelper.parseInternalLink(heading)}`,
		text: heading,
	}));
};

export type StaticTopNavProps = {
	onLoginTabClicked: () => void;
	isLoggedIn?: boolean;
	links?: {
		industry?: NavLink[];
		research?: NavLink[];
		services?: NavLink[];
		welfare?: NavLink[];
		about?: NavLink[];
		media?: NavLink[];
	};
};

/**
 * StaticTopNav doesn't use the TopNavBar component because it wants full CSS control over the layout to be responsive. It sacrifices customisation in favour of layout control.
 * We can use this StaticTopNav in production if we realise that the nav-bar tabs don't change. (LiveCorp staff doesn't need to add/remove tabs).
 * @param props onLoginTabClicked: function to execute when the Login tab is clicked.
 */
export const StaticTopNav = (props: StaticTopNavProps) => {
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);

	const { onLoginTabClicked, links } = props;

	const drawerClass = classNames(
		{ [styles.staticNavDrawerContainerOpen]: isDrawerOpen },
		{ [styles.staticNavDrawerContainerClose]: !isDrawerOpen }
	);

	return (
		<>
			<div className={styles.staticNavContainer}>
				<Link className={styles.staticNavItemLogoPartial} to="/">
					<MainLogo logoOnly={true} />
				</Link>
				<Link className={styles.staticNavItemLogoFull} to="/">
					<MainLogo />
				</Link>
				<StaticVisibleTopNavItem
					id={styles.staticNavIndustry}
					pageUrl={`/${LinkHelper.getPageBaseUrl(PageNames.Industry)}`}
					label="INDUSTRY"
					links={
						links?.industry || getDefaultLinks(PageNames.Industry, defaultIndustryPageSections)
					}
				/>
				<StaticVisibleTopNavItem
					id={styles.staticNavResearchAndDevelopment}
					pageUrl={`/${LinkHelper.getPageBaseUrl(PageNames.Research)}`}
					label="RESEARCH"
					links={
						links?.research ||
						getDefaultLinks(PageNames.Research, defaultResearchAndDevelopmentPageSections)
					}
				/>
				<StaticVisibleTopNavItem
					id={styles.staticNavProgramsAndServices}
					pageUrl={`/${LinkHelper.getPageBaseUrl(PageNames.Services)}`}
					label="PROGRAMS &amp; SERVICES"
					links={
						links?.services ||
						getDefaultLinks(PageNames.Services, defaultProgramsAndServicesPageSections)
					}
				/>
				<StaticVisibleTopNavItem
					id={styles.staticNavWelfare}
					pageUrl={`/${LinkHelper.getPageBaseUrl(PageNames.Welfare)}`}
					label="WELFARE"
					links={links?.welfare || getDefaultLinks(PageNames.Welfare, defaultWelfarePageSections)}
				/>
				<StaticVisibleTopNavItem
					id={styles.staticNavAbout}
					pageUrl={`/${LinkHelper.getPageBaseUrl(PageNames.About)}`}
					label="ABOUT"
					links={links?.about || getDefaultLinks(PageNames.About, defaultAboutPageSections)}
				/>
				<StaticVisibleTopNavItem
					id={styles.staticNavMedia}
					pageUrl={`/${LinkHelper.getPageBaseUrl(PageNames.Media)}`}
					label="MEDIA"
					links={links?.media || getDefaultLinks(PageNames.Media, defaultMediaPageSections)}
				/>
				<div
					id={styles.staticNavLogin}
					className={styles.staticNavItemContainer}
					onClick={onLoginTabClicked}
				>
					<LockIcon scale={0.7} style={{ paddingRight: '10px' }} />
					<span>{props.isLoggedIn === true ? 'LOGOUT' : 'LOGIN'}</span>
				</div>
				<Link
					className={styles.staticNavSearchItem}
					to={`/${LinkHelper.getPageBaseUrl(PageNames.Search)}`}
					activeClassName={styles.staticNavItemActive}
				>
					<SearchIcon />
				</Link>
				<div
					className={styles.staticNavBurgerIconContainer}
					onClick={() => setIsDrawerOpen((prevState) => !prevState)}
				>
					<NavDrawerIcon
						state={isDrawerOpen ? 'opened' : 'closed'}
						style={{ width: '77px', cursor: 'pointer' }}
					/>
				</div>
			</div>
			<div className={drawerClass}>
				<StaticTopNavDrawerItem
					id={styles.staticNavIndustryDrawer}
					setIsDrawerOpen={setIsDrawerOpen}
					pageUrl={`/${LinkHelper.getPageBaseUrl(PageNames.Industry)}`}
					label="INDUSTRY"
					links={
						links?.industry || getDefaultLinks(PageNames.Industry, defaultIndustryPageSections)
					}
				/>
				<StaticTopNavDrawerItem
					id={styles.staticNavResearchAndDevelopmentDrawer}
					setIsDrawerOpen={setIsDrawerOpen}
					pageUrl={`/${LinkHelper.getPageBaseUrl(PageNames.Research)}`}
					label="RESEARCH"
					links={
						links?.research ||
						getDefaultLinks(PageNames.Research, defaultResearchAndDevelopmentPageSections)
					}
				/>
				<StaticTopNavDrawerItem
					id={styles.staticNavProgramsAndServicesDrawer}
					setIsDrawerOpen={setIsDrawerOpen}
					pageUrl={`/${LinkHelper.getPageBaseUrl(PageNames.Services)}`}
					label="PROGRAMS &amp; SERVICES"
					links={
						links?.services ||
						getDefaultLinks(PageNames.Services, defaultProgramsAndServicesPageSections)
					}
				/>
				<StaticTopNavDrawerItem
					id={styles.staticNavWelfareDrawer}
					setIsDrawerOpen={setIsDrawerOpen}
					pageUrl={`/${LinkHelper.getPageBaseUrl(PageNames.Welfare)}`}
					label="WELFARE"
					links={links?.welfare || getDefaultLinks(PageNames.Welfare, defaultWelfarePageSections)}
				/>
				<StaticTopNavDrawerItem
					id={styles.staticNavAboutDrawer}
					setIsDrawerOpen={setIsDrawerOpen}
					pageUrl={`/${LinkHelper.getPageBaseUrl(PageNames.About)}`}
					label="ABOUT"
					links={links?.about || getDefaultLinks(PageNames.About, defaultAboutPageSections)}
				/>
				<StaticTopNavDrawerItem
					id={styles.staticNavMediaDrawer}
					setIsDrawerOpen={setIsDrawerOpen}
					pageUrl={`/${LinkHelper.getPageBaseUrl(PageNames.Media)}`}
					label="MEDIA"
					links={links?.media || getDefaultLinks(PageNames.Media, defaultMediaPageSections)}
				/>
				<div
					id={styles.staticNavLoginDrawer}
					className={styles.staticNavItemDrawer}
					onClick={() => {
						setIsDrawerOpen(false);
						onLoginTabClicked();
					}}
				>
					<LockIcon scale={0.7} style={{ paddingRight: '10px' }} />
					<span>{props.isLoggedIn === true ? 'LOGOUT' : 'LOGIN'}</span>
				</div>
			</div>
		</>
	);
};
