import { Link } from 'gatsby';
import React from 'react';
import { Footer, FooterSection } from 'src/components/footer';
import { LinkedInIcon, TwitterIcon, YouTubeIcon } from 'src/icons/social';
import {
	defaultIndustryPageSections,
	defaultResearchAndDevelopmentPageSections,
	defaultProgramsAndServicesPageSections,
	defaultWelfarePageSections,
} from 'src/utils/common';
import { LinkHelper } from 'src/utils/link-helper';
import { PageNames } from 'src/utils/common';

const industrySection = (
	<FooterSection title="industry info">
		<Link
			to={`/${LinkHelper.getPageBaseUrl(PageNames.Industry)}#${LinkHelper.parseInternalLink(
				defaultIndustryPageSections.overview
			)}`}
		>
			{defaultIndustryPageSections.overview}
		</Link>
		<Link
			to={`/${LinkHelper.getPageBaseUrl(PageNames.Industry)}#${LinkHelper.parseInternalLink(
				defaultIndustryPageSections.industryStatistics
			)}`}
		>
			{defaultIndustryPageSections.industryStatistics}
		</Link>
		<Link
			to={`/${LinkHelper.getPageBaseUrl(PageNames.Industry)}#${LinkHelper.parseInternalLink(
				defaultIndustryPageSections.videos
			)}`}
		>
			{defaultIndustryPageSections.videos}
		</Link>
		<Link
			to={`/${LinkHelper.getPageBaseUrl(PageNames.Industry)}#${LinkHelper.parseInternalLink(
				defaultIndustryPageSections.industryLinks
			)}`}
		>
			{defaultIndustryPageSections.industryLinks}
		</Link>
	</FooterSection>
);

const rAndB = (
	<FooterSection title="research &amp; development">
		<Link
			to={`/${LinkHelper.getPageBaseUrl(PageNames.Research)}#${LinkHelper.parseInternalLink(
				defaultResearchAndDevelopmentPageSections.overview
			)}`}
		>
			{defaultResearchAndDevelopmentPageSections.overview}
		</Link>
		<Link
			to={`/${LinkHelper.getPageBaseUrl(PageNames.Research)}#${LinkHelper.parseInternalLink(
				defaultResearchAndDevelopmentPageSections.lepResearchProgram
			)}`}
		>
			{defaultResearchAndDevelopmentPageSections.lepResearchProgram}
		</Link>
		<Link
			to={`/${LinkHelper.getPageBaseUrl(PageNames.Research)}#${LinkHelper.parseInternalLink(
				defaultResearchAndDevelopmentPageSections.researchProjects
			)}`}
		>
			{defaultResearchAndDevelopmentPageSections.researchProjects}
		</Link>
		<Link
			to={`/${LinkHelper.getPageBaseUrl(PageNames.Research)}#${LinkHelper.parseInternalLink(
				defaultResearchAndDevelopmentPageSections.reports
			)}`}
		>
			{defaultResearchAndDevelopmentPageSections.reports}
		</Link>
	</FooterSection>
);

const pAnS = (
	<FooterSection title="products &amp; services">
		<Link
			to={`/${LinkHelper.getPageBaseUrl(PageNames.Services)}#${LinkHelper.parseInternalLink(
				defaultProgramsAndServicesPageSections.supplyChain
			)}`}
		>
			{defaultProgramsAndServicesPageSections.supplyChain}
		</Link>
		<Link
			to={`/${LinkHelper.getPageBaseUrl(PageNames.Services)}#${LinkHelper.parseInternalLink(
				defaultProgramsAndServicesPageSections.marketAccess
			)}`}
		>
			{defaultProgramsAndServicesPageSections.marketAccess}
		</Link>
		<Link
			to={`/${LinkHelper.getPageBaseUrl(PageNames.Services)}#${LinkHelper.parseInternalLink(
				defaultProgramsAndServicesPageSections.training
			)}`}
		>
			{defaultProgramsAndServicesPageSections.training}
		</Link>
		<Link
			to={`/${LinkHelper.getPageBaseUrl(PageNames.Services)}#${LinkHelper.parseInternalLink(
				defaultProgramsAndServicesPageSections.inMarketServices
			)}`}
		>
			{defaultProgramsAndServicesPageSections.inMarketServices}
		</Link>
	</FooterSection>
);

const Awf = (
	<FooterSection title="animal welfare">
		<Link
			to={`/${LinkHelper.getPageBaseUrl(PageNames.Welfare)}#${LinkHelper.parseInternalLink(
				defaultWelfarePageSections.overview
			)}`}
		>
			{defaultWelfarePageSections.overview}
		</Link>
		<Link
			to={`/${LinkHelper.getPageBaseUrl(PageNames.Welfare)}#${LinkHelper.parseInternalLink(
				defaultWelfarePageSections.projects
			)}`}
		>
			{defaultWelfarePageSections.projects}
		</Link>
		<Link
			to={`/${LinkHelper.getPageBaseUrl(PageNames.Welfare)}#${LinkHelper.parseInternalLink(
				defaultWelfarePageSections.publications
			)}`}
		>
			{defaultWelfarePageSections.publications}
		</Link>
		<Link
			to={`/${LinkHelper.getPageBaseUrl(PageNames.Welfare)}#${LinkHelper.parseInternalLink(
				defaultWelfarePageSections.videos
			)}`}
		>
			{defaultWelfarePageSections.videos}
		</Link>
	</FooterSection>
);

const contact = (
	<FooterSection title="contact" prioritiseOnSmallScreens>
		<div>
			<b>Phone: </b>
			<a href="tel:+61299296755">+61 2 9929 6755</a>
		</div>
		<div>
			<b>Fax: </b>
			<a href="tel:+61299296733">+61 2 9929 6733</a>
		</div>
		<div>
			<b>Email: </b>
			<a href="mailto:livecorp@livecorp.com.au">livecorp@livecorp.com.au</a>
		</div>
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
			}}
		>
			<YouTubeIcon color="#43485A" />
			<TwitterIcon color="#43485A" />
			<LinkedInIcon color="#43485A" />
		</div>
	</FooterSection>
);

export const defaultFooter = (
	<Footer>
		{industrySection}
		{rAndB}
		{pAnS}
		{Awf}
		{contact}
	</Footer>
);
