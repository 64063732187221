import { ApolloManager } from 'src/utils/apollo-manager';
import loginMutation from './login.graphql';
import unauthenticateUserMutation from './unauthenticate-user.graphql';
import authenticatedUserQuery from './authenticated-user.graphql';
import requestPasswordResetMutation from './initiate-password-reset.graphql';
import { useState, useEffect } from 'react';

export interface User {
	id: string;
	name: string;
	email: string;
}

interface BackendLoginResult {
	authenticate?: {
		item?: User;
	};
}

type LoginStatusCallback = (user: User | null) => any;

class LoginManagerImplementation {
	private queryHasRun = false;
	private user: User | null = null;
	private subscribers: LoginStatusCallback[] = [];

	private readonly notifySubscribers = () => {
		for (const subscriber of this.subscribers) {
			subscriber(this.user);
		}
	};

	public readonly onLoginStateChange = (user: User | null) => {
		this.user = user;
		this.notifySubscribers();
	};

	public readonly subscribeToChanges = (callback: LoginStatusCallback) => {
		this.subscribers.push(callback);
	};

	public readonly unsubscribeFromChanges = (callback: LoginStatusCallback) => {
		this.subscribers = this.subscribers.filter((subscriber) => subscriber !== callback);
	};

	public readonly login = async (credentialsParams: { email: string; password: string }) => {
		const credentials = {
			email: credentialsParams.email.toLowerCase(),
			password: credentialsParams.password,
		};
		try {
			const result = await ApolloManager.client.mutate<BackendLoginResult>({
				mutation: loginMutation,
				variables: credentials,
			});

			if (result.data?.authenticate?.item?.id) {
				await ApolloManager.client.clearStore();
				this.onLoginStateChange(result.data.authenticate.item);
				return {
					loggedIn: true,
				};
			}
		} catch (error) {
			console.error('Error logging in: ', error);
			const errorMessage: string | undefined = error?.message;
			if (errorMessage?.includes('[passwordAuth:password:expired]')) {
				return {
					loggedIn: false,
					error: 'Your password has expired, Please check your email for further instructions.',
				};
			}
			if (errorMessage?.includes('[passwordAuth:failure]')) {
				return {
					loggedIn: false,
					error: 'Incorrect details. Please check that your email and password are valid',
				};
			}
		}
		return {
			loggedIn: false,
			error: "Could not login, are you sure you're online?",
		};
	};

	public readonly logout = async (): Promise<void> => {
		const result = await ApolloManager.client.mutate({
			mutation: unauthenticateUserMutation,
		});
		await ApolloManager.client.clearStore();
		if (result.data?.unauthenticateUser?.success) {
			this.onLoginStateChange(null);
		}
	};

	public readonly resetPassword = async (email: string): Promise<boolean> => {
		try {
			const result = await ApolloManager.client.mutate({
				mutation: requestPasswordResetMutation,
				variables: { email: email.toLowerCase() },
			});
			await ApolloManager.client.clearStore();
			if (result.data?.initiateReset?.success) {
				return true;
			}
		} catch (err) {
			console.error(err);
			return false;
		}
		return false;
	};

	private readonly runQuery = async () => {
		// check if gatsby is building. If it is, don't run this query
		if (typeof window !== 'undefined') {
			if (!this.queryHasRun) {
				const result = await ApolloManager.client.query({
					query: authenticatedUserQuery,
				});
				this.user = result.data?.authenticatedUser || null;
				this.notifySubscribers();
				this.queryHasRun = true;
			}
		}
	};

	public readonly isLoggedIn = () => {
		if (!this.queryHasRun) {
			this.runQuery();
			this.queryHasRun = true;
		}
		return this.user !== null;
	};

	public readonly theUser = this.user;
}

export const LoginManager = new LoginManagerImplementation();

export const useLoginManager = () => {
	const [user, setUser] = useState<User | null>(LoginManager.theUser);

	useEffect(() => {
		function handleStatusChange(theUser: User | null) {
			setUser(theUser);
		}

		LoginManager.subscribeToChanges(handleStatusChange);

		return () => {
			LoginManager.unsubscribeFromChanges(handleStatusChange);
		};
	});

	const isLoggedIn = LoginManager.isLoggedIn();

	return {
		isLoggedIn,
	};
};
