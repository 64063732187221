import React, { CSSProperties } from 'react';
import { ImageSvg } from '../utils/customIcon';

interface Props {
	containerClass?: String;
	imgClass?: String;
	containerCss?: CSSProperties;
	imgCss?: CSSProperties;
}

export const LivestockTruck = (props: Props) => {
	const svg = require('./truck.svg');
	return <ImageSvg {...props} svg={svg} />;
};
