import React from 'react';

interface Props {
	size?: number;
}

export const FooterLogo = (props: Props) => {
	const { size } = props;
	const dimension = {
		width: 134 * (size ? size : 1),
		height: 182 * (size ? size : 1),
	};

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={dimension.width}
			height={dimension.height}
			viewBox="0 0 134 182"
		>
			<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
				<g transform="translate(-52 -27)">
					<g>
						<g transform="translate(52 27)">
							<path
								fill="#009C41"
								d="M66.9511589 53.5564187L83.8890093 70.4955215 84.8050935 70.4955215 84.8050935 53.8319327 76.8696636 53.8319327 76.8696636 46.2145963 57.0314019 46.2145963 57.0314019 53.8319327 49.0965981 53.8319327 49.0965981 70.4955215 50.0108037 70.4955215z"
							></path>
							<path
								fill="#009C41"
								d="M122.01 83.911c-5.236 2.699-6.374 5.095-13.49 4.285-6.982-.793-13.649-4.76-16.503-5.554-1.582-.44-4.743-.78-5.985-.903l2.923-2.921L67.903 57.77v33.04h-1.904V57.63L44.947 78.68l3.013 3.013c-.44.041-4.576.425-6.463.95-2.856.793-9.521 4.76-16.504 5.553-7.116.81-8.253-1.586-13.49-4.285C6.266 81.213 1.188 82.8 1.188 82.8V90.1s4.126-.16 6.983 1.11c2.708 1.204 7.773 4.496 13.33 4.762 6.665.317 11.426-1.745 18.885-4.763 7.46-3.014 10.475-1.746 13.173-1.27 1.888.335 3.83 1.512 4.842 2.194l8.617 8.617 9.45-9.449c1.01-.567 2.26-1.144 3.488-1.361 2.697-.477 5.712-1.745 13.17 1.27 7.46 3.017 12.222 5.079 18.887 4.762 5.556-.266 10.622-3.558 13.331-4.763 2.855-1.27 6.983-1.109 6.983-1.109V82.8s-5.08-1.586-10.316 1.112"
							></path>
							<path
								fill="#1D3556"
								d="M42.9745514 57.3231963L27.5395047 41.8887757 41.0565981 41.8887757 49.4948411 50.3270187 66.3732056 33.4505327 49.390271 16.4682243 40.9507757 24.9077196 27.3316168 24.9077196 42.7660374 9.4726729 33.2933645 0 -8.89838566e-15 33.2939907 33.5012523 66.7946168z"
							></path>
							<path
								fill="#1D3556"
								d="M92.3494813 41.8894645L105.865322 41.8894645 90.4302757 57.3232589 99.9029486 66.7946794 133.404827 33.2934271 100.111463 0.0000626168224 90.6400421 9.47273551 106.075089 24.9077822 92.4540514 24.9077822 84.0145561 16.4682869 67.0309953 33.4499692 83.9106121 50.3270813z"
							></path>
							<path
								fill="#1D3556"
								d="M1.18759065 135.596979L1.18759065 107.123232 6.93080561 107.123232 6.93080561 130.890699 17.1010299 130.890699 17.1010299 135.596979z"
							></path>
							<path
								fill="#1D3556"
								d="M19.100072 135.597543L24.017371 135.597543 24.017371 111.217057 19.100072 111.217057z"
							></path>
							<path
								fill="#1D3556"
								d="M31.1580682 111.216807L34.607629 129.247321 34.7103206 129.247321 38.3289467 111.216807 43.4171897 111.216807 37.8173673 135.597293 31.4999561 135.597293 25.9007598 111.216807z"
							></path>
							<path
								fill="#1D3556"
								d="M58.7984486 111.216807L58.7984486 115.245573 50.0897009 115.245573 50.0897009 120.983779 58.2849907 120.983779 58.2849907 125.012545 50.0897009 125.012545 50.0897009 131.568526 59.1384579 131.568526 59.1384579 135.597293 45.1724019 135.597293 45.1724019 111.216807z"
							></path>
							<path
								fill="#1D3556"
								d="M73.437 116.732c0-4.145-.835-5.94-3.429-5.94-2.91 0-3.589 2.91-3.589 10.968 0 8.613 1.197 10.168 3.668 10.168 2.075 0 3.59-1.077 3.59-7.219h5.743c0 6.142-1.515 11.447-9.053 11.447-8.614 0-9.691-6.142-9.691-14.796 0-8.653 1.077-14.795 9.69-14.795 8.218 0 8.815 6.022 8.815 10.167h-5.744z"
							></path>
							<path
								fill="#1D3556"
								d="M89.261 132.455c2.903 0 3.381-2.49 3.381-9.046 0-6.524-.478-9.05-3.38-9.05s-3.381 2.526-3.381 9.05c0 6.555.48 9.046 3.38 9.046m0-21.717c7.375 0 8.297 5.26 8.297 12.67s-.922 12.667-8.297 12.667c-7.375 0-8.296-5.257-8.296-12.666 0-7.41.921-12.67 8.296-12.67"
							></path>
							<path
								fill="#1D3556"
								d="M104.585 121.87h2.049c2.357 0 3.69-1.025 3.69-3.652 0-2.256-1.198-3.38-3.416-3.38h-2.323v7.033zm-4.917-10.653h9.084c4.03 0 6.488 2.117 6.488 6.284 0 3.243-1.296 5.43-4.063 6.008v.07c3.345.445 3.86 2.287 3.926 7.442.033 2.56.172 3.892 1.092 4.37v.206h-5.326c-.478-.684-.58-1.604-.615-2.56l-.136-4.68c-.068-1.91-.922-3.073-2.904-3.073h-2.629v10.313h-4.917v-24.38z"
							></path>
							<path
								fill="#1D3556"
								d="M122.727 122.246h2.184c1.947 0 3.279-1.161 3.279-3.653 0-2.391-.956-3.756-3.417-3.756h-2.046v7.41zm-4.919-11.03h8.605c4.985 0 6.692 3.653 6.692 7.306 0 5.056-3.109 7.344-7.752 7.344h-2.626v9.73h-4.919v-24.38z"
							></path>
							<path
								fill="#1D3556"
								d="M10.3630215 155.408128L8.68551682 155.408128 8.68551682 161.818838 7.13575047 161.818838 7.13575047 155.408128 5.45761963 155.408128 5.45761963 154.138885 10.3630215 154.138885z"
							></path>
							<path
								fill="#1D3556"
								d="M12.6148477 154.138697L12.6148477 157.075426 14.4219692 157.075426 14.4219692 154.138697 15.9711093 154.138697 15.9711093 161.819277 14.4219692 161.819277 14.4219692 158.409164 12.6148477 158.409164 12.6148477 161.819277 11.0650813 161.819277 11.0650813 154.138697z"
							></path>
							<path
								fill="#1D3556"
								d="M21.3237206 155.408128L18.5804776 155.408128 18.5804776 157.21525 21.1621692 157.21525 21.1621692 158.484493 18.5804776 158.484493 18.5804776 160.550221 21.4314215 160.550221 21.4314215 161.818838 17.0319636 161.818838 17.0319636 154.138885 21.3237206 154.138885z"
							></path>
							<path
								fill="#1D3556"
								d="M27.993 158.926l-.743-3.454h-.02l-.743 3.454h1.506zm.204-4.788l2.033 7.681h-1.613l-.355-1.624h-2.044l-.355 1.624H24.25l2.034-7.68h1.914z"
							></path>
							<path
								fill="#1D3556"
								d="M32.163 159.495c0 .194.013.372.038.533.025.161.071.301.14.42a.755.755 0 00.28.28.889.889 0 00.446.102.885.885 0 00.452-.102.769.769 0 00.274-.28c.068-.119.115-.259.14-.42.025-.161.037-.339.037-.533v-5.357h1.55v5.357c0 .488-.069.892-.205 1.21-.136.32-.32.572-.548.759a1.88 1.88 0 01-.786.377 4.252 4.252 0 01-.914.096c-.316 0-.62-.028-.915-.085a1.877 1.877 0 01-.785-.35 1.795 1.795 0 01-.548-.747c-.137-.324-.205-.744-.205-1.26v-5.357h1.55v5.357z"
							></path>
							<path
								fill="#1D3556"
								d="M37.77 159.646c0 .18.014.343.043.49a.845.845 0 00.16.37c.08.101.186.18.318.238.133.057.3.086.5.086.237 0 .45-.077.64-.232.191-.154.286-.392.286-.715 0-.172-.024-.321-.07-.446a.878.878 0 00-.232-.34 1.63 1.63 0 00-.424-.273 6.118 6.118 0 00-.651-.253 5.643 5.643 0 01-.893-.377 2.434 2.434 0 01-.63-.478 1.721 1.721 0 01-.37-.636 2.65 2.65 0 01-.119-.833c0-.76.212-1.327.635-1.7.424-.372 1.003-.56 1.742-.56.345 0 .662.038.953.114.29.075.54.197.752.366.212.168.377.384.496.645.118.262.177.576.177.941v.215h-1.485c0-.365-.064-.647-.193-.844-.13-.198-.344-.296-.646-.296-.171 0-.315.026-.43.076a.672.672 0 00-.415.484 1.46 1.46 0 00-.037.333c0 .237.05.436.15.597.102.162.317.31.646.447l1.194.516a3.6 3.6 0 01.721.404c.187.139.335.29.447.451.111.162.187.339.231.532.043.194.064.41.064.646 0 .81-.235 1.4-.704 1.769-.47.37-1.124.555-1.963.555-.876 0-1.501-.19-1.878-.57-.377-.38-.564-.926-.564-1.635v-.313h1.549v.226z"
							></path>
							<path
								fill="#1D3556"
								d="M46.6413299 155.408128L44.9631991 155.408128 44.9631991 161.818838 43.4140589 161.818838 43.4140589 155.408128 41.7365542 155.408128 41.7365542 154.138885 46.6413299 154.138885z"
							></path>
							<path
								fill="#1D3556"
								d="M49.538 157.495c.373 0 .66-.088.861-.264.201-.175.301-.472.301-.887 0-.71-.36-1.065-1.076-1.065h-.732v2.216h.646zm.667-3.356c.645 0 1.148.162 1.506.489.359.326.538.823.538 1.49 0 .516-.104.938-.312 1.264-.208.327-.531.536-.968.63v.02c.387.058.67.183.85.377.18.194.29.506.333.936.014.144.025.3.033.468l.021.565c.014.408.036.72.065.936.042.215.135.362.28.441v.064h-1.679a.82.82 0 01-.15-.37 4.139 4.139 0 01-.044-.437l-.043-1.473c-.014-.302-.09-.539-.225-.71-.137-.173-.366-.258-.69-.258h-.828v3.248h-1.548v-7.68h2.86z"
							></path>
							<path
								fill="#1D3556"
								d="M56.782 158.926l-.743-3.454h-.02l-.743 3.454h1.506zm.204-4.788l2.034 7.681h-1.614l-.356-1.624h-2.043l-.355 1.624h-1.614l2.034-7.68h1.914z"
							></path>
							<path
								fill="#1D3556"
								d="M59.6569879 154.138697L61.2067542 154.138697 61.2067542 160.550034 63.949371 160.550034 63.949371 161.819277 59.6569879 161.819277z"
							></path>
							<path
								fill="#1D3556"
								d="M64.6995206 161.818964L66.2486607 161.818964 66.2486607 154.138384 64.6995206 154.138384z"
							></path>
							<path
								fill="#1D3556"
								d="M70.662 158.926l-.743-3.454h-.021l-.742 3.454h1.506zm.204-4.788l2.034 7.681h-1.614l-.356-1.624h-2.043l-.354 1.624h-1.615l2.034-7.68h1.914z"
							></path>
							<path
								fill="#1D3556"
								d="M77.1942692 159.399324L77.216185 159.399324 77.216185 154.138885 78.6357084 154.138885 78.6357084 161.818838 76.8711664 161.818838 74.9782598 156.440053 74.9569701 156.440053 74.9569701 161.818838 73.5368206 161.818838 73.5368206 154.138885 75.3226523 154.138885z"
							></path>
							<path
								fill="#1D3556"
								d="M82.2929065 154.138697L83.8414206 154.138697 83.8414206 160.550034 86.5846636 160.550034 86.5846636 161.819277 82.2929065 161.819277z"
							></path>
							<path
								fill="#1D3556"
								d="M87.3355019 161.818964L88.8840159 161.818964 88.8840159 154.138384 87.3355019 154.138384z"
							></path>
							<path
								fill="#1D3556"
								d="M92.3127879 159.818794L92.3453486 159.818794 93.4856009 154.138822 95.0885916 154.138822 93.3240495 161.818776 91.3347131 161.818776 89.570171 154.138822 91.226386 154.138822z"
							></path>
							<path
								fill="#1D3556"
								d="M100.034131 155.408128L97.2908879 155.408128 97.2908879 157.21525 99.8732056 157.21525 99.8732056 158.484493 97.2908879 158.484493 97.2908879 160.550221 100.141832 160.550221 100.141832 161.818838 95.7423738 161.818838 95.7423738 154.138885 100.034131 154.138885z"
							></path>
							<path
								fill="#1D3556"
								d="M102.265 159.646c0 .18.014.343.042.49a.86.86 0 00.162.37.8.8 0 00.317.238c.133.057.3.086.5.086.237 0 .451-.077.64-.232.19-.154.285-.392.285-.715 0-.172-.023-.321-.07-.446a.878.878 0 00-.231-.34 1.63 1.63 0 00-.425-.273 6.101 6.101 0 00-.65-.253 5.602 5.602 0 01-.894-.377 2.43 2.43 0 01-.629-.478 1.721 1.721 0 01-.37-.636 2.65 2.65 0 01-.12-.833c0-.76.212-1.327.636-1.7.423-.372 1.003-.56 1.742-.56.344 0 .662.038.952.114.291.075.542.197.753.366.212.168.376.384.496.645.117.262.177.576.177.941v.215h-1.485c0-.365-.064-.647-.194-.844-.128-.198-.344-.296-.645-.296-.172 0-.316.026-.43.076a.68.68 0 00-.415.484 1.46 1.46 0 00-.036.333c0 .237.049.436.15.597.1.162.315.31.645.447l1.195.516c.293.129.533.263.72.404.186.139.335.29.446.451.112.162.189.339.232.532.042.194.064.41.064.646 0 .81-.235 1.4-.704 1.769-.47.37-1.124.555-1.963.555-.876 0-1.502-.19-1.877-.57-.377-.38-.566-.926-.566-1.635v-.313h1.55v.226z"
							></path>
							<path
								fill="#1D3556"
								d="M111.136344 155.408128L109.457587 155.408128 109.457587 161.818838 107.909073 161.818838 107.909073 155.408128 106.230942 155.408128 106.230942 154.138885 111.136344 154.138885z"
							></path>
							<path
								fill="#1D3556"
								d="M113.228 159.297c.025.362.075.656.15.881.076.228.183.392.323.496.14.104.324.155.555.155.228 0 .414-.051.553-.155.14-.104.248-.268.323-.496.076-.225.125-.519.15-.881.026-.362.038-.802.038-1.318 0-.517-.012-.954-.038-1.312a3.697 3.697 0 00-.15-.882c-.075-.23-.182-.397-.323-.5-.14-.104-.325-.157-.553-.157-.23 0-.415.053-.555.157-.14.103-.247.27-.323.5-.075.23-.125.524-.15.882-.025.358-.038.795-.038 1.312 0 .516.013.956.038 1.318m-1.506-2.926c.054-.492.172-.913.355-1.264.183-.352.446-.625.79-.823.346-.197.807-.297 1.389-.297.58 0 1.043.1 1.386.297.345.198.609.471.792.823.183.351.3.772.355 1.264.053.491.08 1.027.08 1.608 0 .588-.027 1.126-.08 1.614-.054.487-.172.907-.355 1.258-.183.352-.447.62-.792.807-.343.186-.806.28-1.386.28-.582 0-1.043-.094-1.388-.28a1.889 1.889 0 01-.791-.807c-.183-.351-.301-.771-.355-1.258a14.78 14.78 0 01-.081-1.614c0-.58.027-1.117.08-1.608"
							></path>
							<path
								fill="#1D3556"
								d="M120.851 155.51c-.133-.255-.375-.382-.726-.382a.725.725 0 00-.479.157c-.125.103-.223.272-.295.505a4.313 4.313 0 00-.151.914c-.029.377-.043.838-.043 1.382 0 .58.02 1.051.06 1.41.039.358.1.637.182.833.082.197.187.33.313.398a.9.9 0 00.435.103c.136 0 .263-.024.382-.07a.645.645 0 00.307-.28c.085-.14.154-.337.204-.592.05-.254.075-.59.075-1.005h1.55c0 .416-.033.81-.098 1.182a2.67 2.67 0 01-.36.98c-.176.28-.423.498-.743.656-.319.158-.732.237-1.242.237-.581 0-1.043-.094-1.388-.28a1.882 1.882 0 01-.79-.807c-.183-.351-.302-.771-.356-1.258a14.785 14.785 0 01-.08-1.614c0-.58.027-1.117.08-1.608.054-.492.173-.913.356-1.265.184-.35.446-.625.79-.822.345-.197.807-.297 1.388-.297.552 0 .987.09 1.307.27.319.179.558.407.716.683.157.276.255.573.296.893.038.318.058.618.058.898h-1.55c0-.56-.065-.966-.198-1.22"
							></path>
							<path
								fill="#1D3556"
								d="M125.050866 157.215062L125.072156 157.215062 127.030184 154.138697 128.708315 154.138697 126.621922 157.430464 128.945633 161.819277 127.20238 161.819277 125.621306 158.66652 125.050866 159.538772 125.050866 161.819277 123.501726 161.819277 123.501726 154.138697 125.050866 154.138697z"
							></path>
							<path
								fill="#1D3556"
								d="M23.0771794 167.903378L20.3339364 167.903378 20.3339364 169.711125 22.915628 169.711125 22.915628 170.980368 20.3339364 170.980368 20.3339364 173.046097 23.1848804 173.046097 23.1848804 174.314714 18.7847963 174.314714 18.7847963 166.634761 23.0771794 166.634761z"
							></path>
							<path
								fill="#1D3556"
								d="M25.5656346 166.63451L26.5556065 169.119772 27.5349336 166.63451 29.2230832 166.63451 27.4372514 170.430968 29.3527 174.31509 27.6094477 174.31509 26.5017561 171.711482 25.4047093 174.31509 23.7046626 174.31509 25.619485 170.430968 23.8555692 166.63451z"
							></path>
							<path
								fill="#1D3556"
								d="M32.255 170.109c.308 0 .557-.093.747-.28.19-.186.285-.476.285-.87 0-.389-.082-.683-.247-.883-.165-.2-.44-.301-.828-.301h-.645v2.334h.688zm.473-3.475c.395 0 .726.067.996.2.268.132.486.306.65.522.165.214.283.46.355.735.072.277.107.558.107.845 0 .395-.06.74-.177 1.033a1.868 1.868 0 01-.495.721 2.071 2.071 0 01-.769.42c-.301.093-.635.14-1 .14h-.828v3.065h-1.55v-7.68h2.711z"
							></path>
							<path
								fill="#1D3556"
								d="M37.072 171.792c.025.363.076.657.15.882.076.227.184.391.323.495.14.104.325.156.554.156.23 0 .414-.052.554-.156.14-.104.247-.268.323-.495.075-.225.125-.519.15-.882.025-.361.038-.801.038-1.317 0-.517-.013-.954-.038-1.312a3.717 3.717 0 00-.15-.882c-.076-.23-.183-.397-.323-.501-.14-.103-.324-.156-.554-.156-.23 0-.414.053-.554.156-.14.104-.247.27-.323.5a3.75 3.75 0 00-.15.883c-.026.358-.038.795-.038 1.312 0 .516.012.956.038 1.317m-1.506-2.926c.054-.491.172-.912.355-1.264.183-.351.446-.625.79-.823.345-.196.807-.296 1.388-.296.58 0 1.044.1 1.388.296.344.198.608.472.79.823.184.352.302.773.356 1.264.053.492.08 1.028.08 1.609 0 .588-.027 1.125-.08 1.614-.054.487-.172.906-.356 1.257a1.882 1.882 0 01-.79.808c-.344.186-.807.279-1.388.279-.58 0-1.043-.093-1.388-.28a1.885 1.885 0 01-.79-.807c-.183-.35-.301-.77-.355-1.257a14.789 14.789 0 01-.08-1.614c0-.581.026-1.117.08-1.609"
							></path>
							<path
								fill="#1D3556"
								d="M43.806 169.991c.373 0 .66-.088.86-.264.202-.176.302-.472.302-.888 0-.71-.36-1.064-1.077-1.064h-.731v2.216h.646zm.666-3.356c.646 0 1.148.162 1.507.489.358.325.538.823.538 1.49 0 .516-.104.937-.312 1.264-.208.327-.532.536-.969.629v.021c.387.057.671.183.85.377.18.194.29.506.334.936.014.143.025.3.032.467.007.17.015.357.022.566.013.408.035.72.064.935.043.215.136.363.28.442v.064H45.14a.82.82 0 01-.15-.372 4.122 4.122 0 01-.044-.435l-.043-1.473c-.015-.302-.09-.539-.226-.71-.136-.173-.366-.259-.689-.259h-.828v3.249h-1.549v-7.68h2.861z"
							></path>
							<path
								fill="#1D3556"
								d="M52.0958813 167.903378L50.4183766 167.903378 50.4183766 174.314714 48.8692364 174.314714 48.8692364 167.903378 47.1911056 167.903378 47.1911056 166.634761 52.0958813 166.634761z"
							></path>
							<path
								fill="#1D3556"
								d="M58.427 168.006c-.133-.255-.375-.382-.727-.382a.728.728 0 00-.478.156c-.126.104-.225.272-.296.505a4.351 4.351 0 00-.15.915c-.03.376-.044.837-.044 1.382 0 .58.02 1.05.06 1.409.04.359.1.637.183.834.082.197.186.33.311.398.126.068.27.102.436.102.136 0 .263-.023.382-.07a.648.648 0 00.306-.279c.086-.14.154-.338.205-.592.05-.255.075-.59.075-1.006h1.55c0 .416-.033.81-.098 1.183-.064.373-.184.7-.36.98-.176.278-.423.498-.742.656-.32.157-.734.236-1.242.236-.582 0-1.044-.093-1.388-.28a1.874 1.874 0 01-.79-.807c-.184-.35-.302-.77-.356-1.257a14.793 14.793 0 01-.08-1.615c0-.58.026-1.116.08-1.608.054-.491.172-.912.356-1.264.182-.351.445-.625.79-.823.344-.196.806-.296 1.388-.296.551 0 .987.09 1.306.27.32.179.557.406.716.683.158.276.256.573.295.893.04.318.06.618.06.897h-1.55c0-.559-.066-.966-.198-1.22"
							></path>
							<path
								fill="#1D3556"
								d="M62.547 171.792c.025.363.075.657.15.882.076.227.183.391.323.495.14.104.324.156.554.156.23 0 .413-.052.554-.156.14-.104.247-.268.323-.495.074-.225.125-.519.15-.882.025-.361.038-.801.038-1.317 0-.517-.013-.954-.038-1.312a3.75 3.75 0 00-.15-.882c-.076-.23-.184-.397-.323-.501-.14-.103-.325-.156-.554-.156-.23 0-.414.053-.554.156-.14.104-.247.27-.323.5a3.75 3.75 0 00-.15.883c-.026.358-.039.795-.039 1.312 0 .516.013.956.039 1.317m-1.506-2.926c.053-.491.171-.912.355-1.264.182-.351.446-.625.79-.823.344-.196.807-.296 1.388-.296.58 0 1.043.1 1.387.296.344.198.609.472.79.823.184.352.302.773.356 1.264.054.492.08 1.028.08 1.609 0 .588-.026 1.125-.08 1.614-.054.487-.172.906-.355 1.257a1.88 1.88 0 01-.79.808c-.345.186-.807.279-1.388.279-.581 0-1.044-.093-1.388-.28a1.882 1.882 0 01-.79-.807c-.184-.35-.302-.77-.355-1.257a14.789 14.789 0 01-.081-1.614c0-.581.027-1.117.08-1.609"
							></path>
							<path
								fill="#1D3556"
								d="M69.28 169.991c.373 0 .66-.088.86-.264.202-.176.302-.472.302-.888 0-.71-.359-1.064-1.076-1.064h-.731v2.216h.646zm.667-3.356c.646 0 1.147.162 1.506.489.358.325.538.823.538 1.49 0 .516-.103.937-.312 1.264-.208.327-.531.536-.969.629v.021c.388.057.672.183.85.377.18.194.29.506.334.936.014.143.025.3.033.467.006.17.015.357.02.566.015.408.036.72.065.935.043.215.137.363.28.442v.064h-1.679a.826.826 0 01-.149-.372 3.816 3.816 0 01-.043-.435l-.044-1.473c-.014-.302-.09-.539-.225-.71-.137-.173-.365-.259-.689-.259h-.828v3.249h-1.549v-7.68h2.86z"
							></path>
							<path
								fill="#1D3556"
								d="M75.288 170.109c.308 0 .558-.093.747-.28.19-.186.285-.476.285-.87 0-.389-.082-.683-.247-.883-.165-.2-.441-.301-.828-.301H74.6v2.334h.689zm.473-3.475c.395 0 .726.067.995.2.269.132.486.306.651.522.165.214.283.46.355.735.071.277.107.558.107.845 0 .395-.06.74-.177 1.033a1.88 1.88 0 01-.495.721 2.07 2.07 0 01-.77.42c-.3.093-.633.14-1 .14H74.6v3.065H73.05v-7.68h2.711z"
							></path>
							<path
								fill="#1D3556"
								d="M80.105 171.792c.025.363.075.657.15.882.075.227.183.391.323.495.14.104.325.156.554.156.23 0 .415-.052.555-.156.14-.104.246-.268.322-.495.075-.225.125-.519.15-.882.026-.361.039-.801.039-1.317 0-.517-.013-.954-.039-1.312a3.717 3.717 0 00-.15-.882c-.076-.23-.183-.397-.322-.501-.14-.103-.325-.156-.555-.156-.23 0-.414.053-.554.156-.14.104-.248.27-.323.5a3.717 3.717 0 00-.15.883c-.025.358-.038.795-.038 1.312 0 .516.013.956.038 1.317m-1.506-2.926c.054-.491.172-.912.355-1.264a1.99 1.99 0 01.79-.823c.345-.196.807-.296 1.388-.296.58 0 1.044.1 1.387.296.345.198.608.472.792.823.182.352.301.773.355 1.264.053.492.08 1.028.08 1.609 0 .588-.027 1.125-.08 1.614-.054.487-.173.906-.355 1.257a1.885 1.885 0 01-.792.808c-.343.186-.806.279-1.387.279-.58 0-1.043-.093-1.387-.28a1.883 1.883 0 01-.79-.807c-.184-.35-.302-.77-.356-1.257a14.789 14.789 0 01-.08-1.614c0-.581.026-1.117.08-1.609"
							></path>
							<path
								fill="#1D3556"
								d="M86.84 169.991c.372 0 .659-.088.86-.264.2-.176.3-.472.3-.888 0-.71-.358-1.064-1.074-1.064h-.733v2.216h.646zm.666-3.356c.645 0 1.147.162 1.506.489.358.325.537.823.537 1.49 0 .516-.103.937-.311 1.264-.208.327-.53.536-.968.629v.021c.386.057.67.183.85.377.179.194.29.506.333.936.014.143.025.3.033.467.006.17.014.357.02.566.015.408.036.72.065.935.044.215.136.363.28.442v.064h-1.678a.827.827 0 01-.15-.372 3.963 3.963 0 01-.044-.435l-.043-1.473c-.014-.302-.09-.539-.225-.71-.137-.173-.366-.259-.689-.259h-.829v3.249h-1.548v-7.68h2.86z"
							></path>
							<path
								fill="#1D3556"
								d="M94.083 171.421l-.743-3.453h-.021l-.742 3.453h1.506zm.204-4.787l2.033 7.68h-1.613l-.355-1.624h-2.044l-.355 1.625H90.34l2.034-7.68h1.914z"
							></path>
							<path
								fill="#1D3556"
								d="M100.826609 167.903378L99.1484785 167.903378 99.1484785 174.314714 97.5999645 174.314714 97.5999645 167.903378 95.9218336 167.903378 95.9218336 166.634761 100.826609 166.634761z"
							></path>
							<path
								fill="#1D3556"
								d="M101.568306 174.315403L103.117446 174.315403 103.117446 166.634823 101.568306 166.634823z"
							></path>
							<path
								fill="#1D3556"
								d="M105.646 171.792c.025.363.076.657.15.882.076.227.184.391.324.495.14.104.324.156.555.156.228 0 .413-.052.553-.156.14-.104.247-.268.323-.495.075-.225.125-.519.15-.882.025-.361.038-.801.038-1.317 0-.517-.013-.954-.038-1.312a3.685 3.685 0 00-.15-.882c-.076-.23-.183-.397-.323-.501-.14-.103-.325-.156-.553-.156-.23 0-.416.053-.555.156-.14.104-.248.27-.323.5a3.75 3.75 0 00-.15.883 19.27 19.27 0 00-.038 1.312c0 .516.012.956.037 1.317m-1.506-2.926c.054-.491.173-.912.355-1.264.184-.351.447-.625.791-.823.345-.196.807-.296 1.389-.296.58 0 1.043.1 1.386.296.345.198.608.472.791.823.183.352.302.773.355 1.264.054.492.08 1.028.08 1.609 0 .588-.026 1.125-.08 1.614-.053.487-.172.906-.355 1.257a1.881 1.881 0 01-.791.808c-.343.186-.807.279-1.386.279-.582 0-1.044-.093-1.389-.28a1.887 1.887 0 01-.79-.807c-.183-.35-.302-.77-.356-1.257a14.789 14.789 0 01-.08-1.614c0-.581.027-1.117.08-1.609"
							></path>
							<path
								fill="#1D3556"
								d="M113.843457 171.894574L113.865373 171.894574 113.865373 166.634761 115.284896 166.634761 115.284896 174.314714 113.520354 174.314714 111.628074 168.935929 111.606158 168.935929 111.606158 174.314714 110.186008 174.314714 110.186008 166.634761 111.97184 166.634761z"
							></path>
							<path
								fill="#1D3556"
								d="M1.21921215 148.388281L132.326315 148.388281 132.326315 147.049534 1.21921215 147.049534z"
							></path>
							<path
								fill="#1D3556"
								d="M1.21921215 181.467496L132.326315 181.467496 132.326315 180.129375 1.21921215 180.129375z"
							></path>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};
