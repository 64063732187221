import styles from './styles.module.css';
import { navigate } from '@reach/router';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { OnSubmit } from 'react-hook-form/dist/types';
import { CustomButton } from 'src/components/formComponents/customButton';
import { CustomInput } from 'src/components/formComponents/customInput';
import { CaretIcon } from 'src/icons/caret';
import { CloseIcon } from 'src/icons/close';
import { LoginManager, useLoginManager } from 'src/utils/login-manager';
import { LinkHelper } from 'src/utils/link-helper';
import { PageNames } from 'src/utils/common';

export type LoginResult = {
	loggedIn: boolean;
	error?: string;
};

interface Props {
	onLogin: (result: LoginResult) => any;
	onClose: () => any;
}

interface NormalLoginFormValues {
	email: string;
	password: string;
	globalError: string;
}

interface RequesPasswordResetFormValues {
	email: string;
	globalError: string;
}

const rules = {
	email: {
		required: {
			value: true,
			message: 'Please provide your email',
		},
	},
	password: {
		required: {
			value: true,
			message: 'Please provide your password',
		},
	},
};

enum LoginModes {
	Normal = 1,
	RequestPassword,
	RequestPasswordSubmittedOk,
	AddNewPassword,
}

const LoginForm = (props: Props) => {
	const [mode, setMode] = useState<LoginModes>(LoginModes.Normal);
	const { register, handleSubmit, errors, setError, clearError } = useForm<NormalLoginFormValues>();

	const onNormalLoginSubmit: OnSubmit<NormalLoginFormValues> = async (data) => {
		clearError();
		const result = await LoginManager.login(data);
		if (result.loggedIn) {
			if (props.onLogin) {
				props.onLogin(result);
				return;
			} else {
				navigate('/');
			}
		} else {
			setError(
				'globalError',
				'generic',
				result.error || "Could not login, are you sure you're online?"
			);
		}
	};

	const onRequestPasswordReset: OnSubmit<RequesPasswordResetFormValues> = async (data) => {
		clearError();
		const result = await LoginManager.resetPassword(data.email);
		if (result) {
			setMode(LoginModes.RequestPasswordSubmittedOk);
		} else {
			setError(
				'globalError',
				'generic',
				'Failed to reset your password. Apologies for the inconvenience. Please try again later or contact us directly.'
			);
		}
	};

	const getPasswordInput = () => {
		if (mode === LoginModes.Normal) {
			return (
				<CustomInput
					name="password"
					placeHolder="Password"
					outerContainerStyle={{ margin: '15px 0' }}
					type="password"
					errorMessage={errors?.password?.message as string}
					ref={register(rules.password)}
				/>
			);
		}
		return undefined;
	};

	const getHeaderMsg = () => {
		switch (mode) {
			case LoginModes.Normal:
				return 'Log In';
			case LoginModes.RequestPassword:
			case LoginModes.RequestPasswordSubmittedOk:
				return 'Reset Password';
			case LoginModes.AddNewPassword:
				return 'New Password';
			default:
				return '';
		}
	};

	const getHandleSubmit = () => {
		switch (mode) {
			case LoginModes.Normal:
				return onNormalLoginSubmit;
			case LoginModes.RequestPassword:
				return onRequestPasswordReset;
			default:
				return () => {
					console.error(`No submit handle for ${LoginModes[mode]}`);
				};
		}
	};

	const getSubmitSection = () => {
		const getSubmitButtonText = () => {
			switch (mode) {
				case LoginModes.Normal:
					return 'Log In';
				case LoginModes.RequestPassword:
					return 'Reset Password';
				case LoginModes.AddNewPassword:
					return 'Add New Password';
				default:
					return 'Submit';
			}
		};

		switch (mode) {
			case LoginModes.Normal:
			case LoginModes.RequestPassword:
			case LoginModes.AddNewPassword:
				return (
					<CustomButton
						type="submit"
						variant="primary"
						content={getSubmitButtonText()}
						iconRight={<CaretIcon direction="right" paddingPx={{ left: 50 }} />}
					/>
				);
			case LoginModes.RequestPasswordSubmittedOk:
				return (
					<span className={styles.submissionResultText}>
						An email has been sent to you to reset your password. This may take several minutes to arrive - please also check your spam folder.
					</span>
				);
		}
	};

	const getForgotPasswordSection = () => {
		switch (mode) {
			case LoginModes.Normal:
				return (
					<span
						className={styles.forgotPassword}
						onClick={() => setMode(LoginModes.RequestPassword)}
					>
						Forgot password?
					</span>
				);
			case LoginModes.RequestPassword:
				return (
					<span className={styles.forgotPassword} onClick={() => setMode(LoginModes.Normal)}>
						I Remember Now
					</span>
				);
			default:
				return undefined;
		}
	};

	return (
		<form onSubmit={handleSubmit(getHandleSubmit())}>
			<div className={styles.headerContainer}>
				<h2 className={styles.loginHeader}>{getHeaderMsg()}</h2>
				<button className={styles.closeIconContainer} type="button" onClick={props.onClose}>
					<CloseIcon scale={0.9} />
				</button>
			</div>
			<div className={styles.inputsContainer}>
				<CustomInput
					name="email"
					placeHolder="Email"
					outerContainerStyle={{ margin: '15px 0' }}
					type="text"
					errorMessage={errors?.email?.message as string}
					ref={register(rules.email)}
				/>
				{getPasswordInput()}
			</div>
			<div className={styles.rememberMeContainer}>
				<div></div>
				{/* <CustomCheckbox labelJsx={'Remember me'} /> */}
				{getForgotPasswordSection()}
			</div>
			<div className={styles.loginButtonContainer}>{getSubmitSection()}</div>
			<div className={styles.applyContainer}>
				Don't have a login? &nbsp;
				<AnchorLink to={`/${LinkHelper.getPageBaseUrl(PageNames.RequestSignUp)}`}>Apply</AnchorLink>
				&nbsp; or &nbsp;
				<AnchorLink to={`/${LinkHelper.getPageBaseUrl(PageNames.ContactUs)}`}>
					contact us
				</AnchorLink>
			</div>
			<div className={styles.customErrorMessage}>{errors?.globalError?.message}</div>
		</form>
	);
};

const LogoutForm = (props: Props) => {
	const { handleSubmit } = useForm<NormalLoginFormValues>();

	const onSubmit: OnSubmit<NormalLoginFormValues> = async (data) => {
		await LoginManager.logout();
		props.onClose();
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className={styles.headerContainer}>
				<h2 className={styles.loginHeader}>Log Out</h2>
				<button type="button" onClick={props.onClose}>
					<CloseIcon scale={0.9} />
				</button>
			</div>
			<div className={styles.confirmLogout}>Are you sure you want to logout?</div>
			<div className={styles.loginButtonContainer}>
				<CustomButton
					type="submit"
					variant="secondary"
					content="Log Out"
					iconRight={<CaretIcon direction="right" paddingPx={{ left: 50 }} />}
				/>
			</div>
		</form>
	);
};

export const LoginFormPopup = (props: Props) => {
	const loginManager = useLoginManager();

	return (
		<div className={styles.outerContainer}>
			<div className={styles.loginContainer}>
				{loginManager.isLoggedIn ? <LogoutForm {...props} /> : <LoginForm {...props} />}
			</div>
		</div>
	);
};
