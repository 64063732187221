import styles from './styles.module.css';
import React, { CSSProperties } from 'react';

interface Props {
	color?: string;
	scale?: number;
	style?: CSSProperties;
}

export const Upload = (props: Props) => {
	const { color, scale, style } = props;
	const fill = color ? color : 'currentColor';
	const width = 17 * (scale ? scale : 1);
	const height = 17 * (scale ? scale : 1);
	return (
		<span className={styles.iconWrapper} style={style}>
			<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 17 17">
				<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
					<g fill={fill} transform="translate(-430 -1149)">
						<g transform="translate(430 1149)">
							<path d="M.844 17C.387 17 0 16.662 0 16.222V8.778C0 8.356.37 8 .844 8s.844.338.844.778v6.682h13.624V8.778c0-.422.37-.778.844-.778.475 0 .844.338.844.778v7.444a.865.865 0 01-.862.778H.844z"></path>
							<path d="M9.59.235a.818.818 0 00-1.147 0L5.24 3.455a.836.836 0 00-.24.591c0 .227.086.418.24.575a.8.8 0 001.147 0l1.748-1.758v8.267c0 .487.376.87.856.87s.857-.383.857-.87V2.828l1.765 1.793a.818.818 0 001.147 0 .826.826 0 000-1.166L9.59.235z"></path>
						</g>
					</g>
				</g>
			</svg>
		</span>
	);
};
