import styles from './styles.module.css';
import React from 'react';

interface Props {
	state?: 'open' | 'close';
	color?: string;
	scale?: number;
}

const openIconSvg = (fill: string, width: number, height: number) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 37 37">
		<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
			<g fill={fill} transform="translate(-1218 -1075)">
				<g transform="translate(1218 1075)">
					<path d="M5.594 5.594c-7.136 7.135-7.136 18.72 0 25.855 7.135 7.135 18.72 7.135 25.855 0 7.135-7.135 7.135-18.72 0-25.855-7.135-7.136-18.763-7.136-25.855 0zm23.698 23.698c-5.947 5.946-15.637 5.946-21.584 0-5.946-5.947-5.946-15.637 0-21.584 5.947-5.946 15.637-5.946 21.584 0 5.946 5.947 5.946 15.637 0 21.584z"></path>
					<path d="M17.003 17.046h-5.726c-.836 0-1.497.66-1.497 1.497 0 .836.66 1.497 1.497 1.497H25.723c.396 0 .793-.176 1.057-.44.265-.265.44-.661.44-1.057 0-.836-.66-1.497-1.497-1.497h-8.72z"></path>
				</g>
			</g>
		</g>
	</svg>
);

const closeIconSvg = (fill: string, width: number, height: number) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 37 38">
		<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
			<g fill={fill} transform="translate(-1034 -30)">
				<path d="M1039.594 36.094c-7.136 7.135-7.136 18.72 0 25.855 7.135 7.135 18.72 7.135 25.855 0 7.135-7.135 7.135-18.72 0-25.855-7.135-7.136-18.763-7.136-25.855 0zm23.698 23.698c-5.947 5.946-15.637 5.946-21.584 0-5.946-5.947-5.946-15.637 0-21.584 5.947-5.946 15.637-5.946 21.584 0 5.946 5.947 5.946 15.637 0 21.584z"></path>
				<path d="M1052.5 40.323c-.836 0-1.497.66-1.497 1.497v5.726h-5.726c-.836 0-1.497.66-1.497 1.497 0 .836.66 1.497 1.497 1.497h5.726v5.726c0 .836.66 1.497 1.497 1.497.396 0 .793-.175 1.056-.44.265-.265.44-.661.44-1.057V50.54h5.727c.396 0 .793-.176 1.057-.44.265-.265.44-.661.44-1.057 0-.836-.66-1.497-1.497-1.497h-5.726V41.82c0-.836-.66-1.497-1.497-1.497z"></path>
			</g>
		</g>
	</svg>
);

export const CollapsibleIcon = (props: Props) => {
	const { state, color, scale: size } = props;
	const fill = color ? color : 'currentColor';
	const width = 37 * (size ? size : 1);
	const height = 37 * (size ? size : 1);
	return (
		<span className={styles.iconWrapper}>
			{state === 'open' ? openIconSvg(fill, width, height) : closeIconSvg(fill, width, height)}
		</span>
	);
};
