import styles from './styles.module.css';
import React, { CSSProperties } from 'react';
import classNames from 'classnames';

interface Props {
	containerClass?: string;
	imgClass?: string;
	containerCss?: CSSProperties;
	imgCss?: CSSProperties;
	svg: string;
}

export const ImageSvg = (props: Props) => {
	const { containerClass, imgClass, containerCss, imgCss, svg } = props;
	const spanClass = classNames([styles.iconWrapper], containerClass);
	const imageClass = classNames([styles.img], imgClass);
	return (
		<span className={spanClass} style={containerCss}>
			<img className={imageClass} style={imgCss} src={svg} draggable={false} />
		</span>
	);
};
