import {
	BackEndContentTypes,
	ContentfulTypes,
	PageNames,
	SupportedSearchContentTypes,
} from './common';
import { collapsiblePanelMap } from '../../src/templates/utils/richTextOptions/collapsiblePanelMap';
import { AllResourcesGroup } from 'src/graphql-types';

class LinkHelperImplementation {
	public readonly linkTo = (item: AllResourcesGroup | null | undefined) => {
		return item
			? this.getLinkOfContentType(item.internal.type as ContentfulTypes, item.contentful_id)
			: '/';
	};

	public readonly getLinkOfContentType = (
		resourceType: ContentfulTypes | BackEndContentTypes | SupportedSearchContentTypes | undefined,
		resourceId: string | null | undefined
	) => {
		const resourceIdStr = resourceId || '';
		switch (resourceType) {
			case BackEndContentTypes.Article:
			case ContentfulTypes.Article:
			case SupportedSearchContentTypes.ArticleResource:
				return `/article/${resourceIdStr}`;
			case BackEndContentTypes.Publication:
			case ContentfulTypes.Publication:
			case SupportedSearchContentTypes.PublicationResource:
				return `/publication/${resourceIdStr}`;
			case BackEndContentTypes.Project:
			case ContentfulTypes.Project:
			case SupportedSearchContentTypes.ProjectResource:
				return `/project/${resourceIdStr}`;
			case BackEndContentTypes.Report:
			case ContentfulTypes.Report:
			case SupportedSearchContentTypes.ReportResource:
				return `/report/${resourceIdStr}`;
			case BackEndContentTypes.Video:
			case ContentfulTypes.Video:
			case SupportedSearchContentTypes.VideoResource:
				return `/video/${resourceIdStr}`;
			case SupportedSearchContentTypes.HomePage:
			case ContentfulTypes.ContentfulHomePage:
				return '/';
			case SupportedSearchContentTypes.IndustryPage:
			case ContentfulTypes.ContentfulIndustryPage:
				return '/industry';
			case SupportedSearchContentTypes.ResearchPage:
			case ContentfulTypes.ContentfulResearchAndDevelopmentPage:
				return '/researchAndDevelopment';
			case SupportedSearchContentTypes.ServicesPage:
			case ContentfulTypes.ContentfulProgramsAndServicesPage:
				return '/programsAndServices';
			case SupportedSearchContentTypes.WelfarePage:
			case ContentfulTypes.ContentfulWelfarePage:
				return '/welfare';
			case SupportedSearchContentTypes.AboutPage:
			case ContentfulTypes.ContentfulAboutPage:
				return '/about';
			case SupportedSearchContentTypes.MediaPage:
			case ContentfulTypes.ContentfulMediaPage:
				return '/media';
			case SupportedSearchContentTypes.CollapsiblePanel:
			case SupportedSearchContentTypes.FormTab:
				return resourceId ? collapsiblePanelMap[resourceId] : '/';
			default:
				return '/';
		}
	};

	/**
	 * returns a string that can be use as ID to an element and as hash in a URL to scroll to that element.
	 */
	public readonly parseInternalLink = (linkLabel: string) => {
		return linkLabel
			.trim()
			.replace(/\s/g, '-')
			.replace(/[^a-zA-Z0-9-_]/g, '');
	};

	/**
	 * returns the page name to use as base URL
	 * @param page selected option in Contentful under 'pages' field, or a valid page URL
	 */
	public readonly getPageBaseUrl = (
		page: string | null | undefined | PageNames | ContentfulTypes
	) => {
		switch (page) {
			case ContentfulTypes.HomePage:
			case ContentfulTypes.ContentfulHomePage:
				return '';
			case PageNames.Industry:
			case ContentfulTypes.IndustryPage:
			case ContentfulTypes.ContentfulIndustryPage:
				return 'industry';
			case PageNames.Research:
			case ContentfulTypes.ResearchAndDevelopmentPage:
			case ContentfulTypes.ContentfulResearchAndDevelopmentPage:
				return 'researchAndDevelopment';
			case PageNames.Services:
			case ContentfulTypes.ProgramsAndServicesPage:
			case ContentfulTypes.ContentfulProgramsAndServicesPage:
				return 'programsAndServices';
			case PageNames.Welfare:
			case ContentfulTypes.WelfarePage:
			case ContentfulTypes.ContentfulWelfarePage:
				return 'welfare';
			case PageNames.Media:
			case ContentfulTypes.MediaPage:
			case ContentfulTypes.ContentfulMediaPage:
				return 'media';
			case PageNames.About:
			case ContentfulTypes.AboutPage:
			case ContentfulTypes.ContentfulAboutPage:
				return 'about';
			case PageNames.ContactUs:
			case ContentfulTypes.ContactUsPage:
			case ContentfulTypes.ContentfulContactUsPage:
				return 'contactUs';
			case PageNames.Membership:
				return 'membership';
			case PageNames.Search:
				return 'search';
			case PageNames.RequestSignUp:
				return 'request-sign-up';
			default:
				return '';
		}
	};
}

export const LinkHelper = new LinkHelperImplementation();
