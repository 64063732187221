import styles from './styles.module.css';
import classNames from 'classnames';
import { Link } from 'gatsby';
import React from 'react';
import { FooterLogo } from 'src/icons/footerLogo';

interface SectionProps {
	title?: string | null;
	to?: string;
	prioritiseOnSmallScreens?: boolean;
	children: any;
}

export const FooterSection = (props: SectionProps) => {
	const { title, to, children, prioritiseOnSmallScreens } = props;
	const containerClass = classNames([
		styles.sectionContainer,
		{ [styles.sectionGetPriority]: prioritiseOnSmallScreens },
	]);

	if (to) {
		return (
			<div className={containerClass}>
				<Link className={styles.containerTitle} to={to}>
					{title}
				</Link>
				{children}
			</div>
		);
	}

	return (
		<div className={containerClass}>
			<h6 className={styles.containerTitle}>{title}</h6>
			{children}
		</div>
	);
};

type SectionComponent =
	| React.ReactElement<typeof FooterSection>
	| (React.ReactElement<typeof FooterSection> | undefined)[]
	| undefined
	| any;

interface FooterProps {
	children: SectionComponent;
	abn?: string | null;
	copyRightSectionLinks?: {
		label: string;
		slug: string;
	}[];
}

export const Footer = (props: FooterProps) => {
	const getCopyright = () => {
		const { abn, copyRightSectionLinks: links } = props;
		const abnSection = abn ? `LiveCorp ABN ${abn}` : '';
		const copyright = `© Copyright ${new Date().getFullYear()} ${abnSection} All rights reserved. `;
		return (
			<div className={styles.copyright}>
				{copyright}
				{links
					?.filter((l) => l)
					.map((l) => (
						<span key={l.slug}>
							&nbsp; •{' '}
							<Link className={styles.copyrightLink} to={`/${l.slug}`}>
								{l.label}
							</Link>
							&nbsp;
						</span>
					))}
			</div>
		);
	};
	return (
		<>
			<div className={styles.wrapper}>
				<div className={styles.logoContainer}>
					<FooterLogo />
				</div>
				{props.children}
			</div>
			{getCopyright()}
		</>
	);
};
