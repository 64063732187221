import styles from './styles.module.css';
import classNames from 'classnames';
import React, { ReactNode, useEffect, useState } from 'react';
import { defaultFooter } from 'src/components/footer/defaultFooter';
import { StaticTopNav } from 'src/components/topNavbar/defaultTopNavBar';
import { CaretIcon } from 'src/icons';

interface Props {
	children: ReactNode;
	header?: ReactNode;
	footer?: ReactNode;
}

const ScrollToTopButton = () => {
	const [isVisible, setIsVisible] = useState(false);
	if (typeof window !== 'undefined') {
		useEffect(() => {
			window.addEventListener('scroll', () => {
				if (window.pageYOffset > 200 && !isVisible) {
					setIsVisible(true);
				}
				if (window.pageYOffset <= 200 && isVisible) {
					setIsVisible(false);
				}
			});
		}, [window]);

		const containerClass = classNames(styles.scrollToTopButton, {
			[styles.scrollToTopButtonVisible]: isVisible,
		});

		const scrollToTop = () => {
			if (typeof window !== 'undefined') {
				window.scrollTo({
					top: 0,
					behavior: 'smooth',
				});
			}
		};

		return (
			<div className={containerClass} onClick={scrollToTop}>
				<CaretIcon direction="up" scale={3.5} />
			</div>
		);
	} else {
		return <></>;
	}
};

export const DefaultLayout: React.FC<Props> = ({
	children,
	header = (
		<StaticTopNav
			onLoginTabClicked={() => {
				'Do Nothing';
			}}
		/>
	),
	footer = defaultFooter,
}) => {
	return (
		<>
			{header}
			<div className={styles.childrenContainer}>{children}</div>
			{footer}
			<ScrollToTopButton />
		</>
	);
};
