import styles from './styles.module.css';
import classNames from 'classnames';
import React from 'react';

interface Props {
	direction?: 'up' | 'right' | 'down' | 'left';
	color?: string;
	paddingPx?: {
		left?: number;
		right?: number;
	};
	/** growing factor. @example: 2 - makes the icon twice as big */
	scale?: number;
	className?: string;
}

export const CaretIcon = (props: Props) => {
	const { direction, color, paddingPx, scale, className } = props;
	const svgClass = styles[direction || 'right'];
	const fill = color ? color : 'currentColor';
	const outerPadding = {
		paddingLeft: paddingPx?.left,
		paddingRight: paddingPx?.right,
	};
	const width = 8 * (scale ? scale : 1);
	const height = 11 * (scale ? scale : 1);
	const containerClass = classNames([styles.iconWrapper, className]);
	return (
		<span className={containerClass} style={outerPadding}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={width}
				height={height}
				viewBox="0 0 8 11"
				className={svgClass}
			>
				<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
					<g fill={fill} transform="translate(-786 -2463)">
						<g transform="translate(629 2447)">
							<path
								d="M163.981421 18L160.960477 21.0888127 157.870762 18.0669667 156 20.0064318 161.005369 24.8990625 165.898 19.8936932z"
								transform="rotate(-90 160.949 21.45)"
							></path>
						</g>
					</g>
				</g>
			</svg>
		</span>
	);
};
