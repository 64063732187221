import styles from './styles.module.css';
import { graphql, useStaticQuery } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import React, { ReactNode, useState, useEffect } from 'react';
import { Footer, FooterSection } from 'src/components/footer';
import { LoginFormPopup, LoginResult } from 'src/components/loginComponents/loginFormPopup';
import {
	StaticTopNav,
	StaticTopNavProps,
	NavLink,
} from 'src/components/topNavbar/defaultTopNavBar';
import { ContentfulFooter, Maybe, ContentfulPage } from 'src/graphql-types';
import { LinkedInIcon, TwitterIcon, YouTubeIcon } from 'src/icons/social';
import { DefaultLayout } from 'src/layouts/default';
import {
	defaultIndustryPageSections,
	defaultAboutPageSections,
	defaultResearchAndDevelopmentPageSections,
	defaultProgramsAndServicesPageSections,
	defaultWelfarePageSections,
	defaultMediaPageSections,
	ContentfulTypes,
	getPageName,
} from 'src/utils/common';
import { PageNames } from 'src/utils/common';
import { LinkHelper } from 'src/utils/link-helper';
import { useLoginManager } from 'src/utils/login-manager';

const queryQl = graphql`
	query Footer {
		allContentfulFooter(sort: { fields: updatedAt, order: DESC }, limit: 1) {
			nodes {
				contentful_id
				contactDetails {
					phone
					fax
					email
				}
				linkToYoutube
				linkToTwitter
				linkToLinkedIn
				pages
				abn
				linksToPages {
					... on ContentfulAboutPage {
						sys {
							contentType {
								sys {
									id
								}
							}
						}
					}
					... on ContentfulContactUsPage {
						sys {
							contentType {
								sys {
									id
								}
							}
						}
					}
					... on ContentfulHomePage {
						sys {
							contentType {
								sys {
									id
								}
							}
						}
					}
					... on ContentfulWelfarePage {
						sys {
							contentType {
								sys {
									id
								}
							}
						}
					}
					... on ContentfulResearchDevelopmentPage {
						sys {
							contentType {
								sys {
									id
								}
							}
						}
					}
					... on ContentfulProgramsAndServicesPage {
						sys {
							contentType {
								sys {
									id
								}
							}
						}
					}
					... on ContentfulPage {
						sys {
							contentType {
								sys {
									id
								}
							}
						}
						url
						title
					}
					... on ContentfulMediaPage {
						sys {
							contentType {
								sys {
									id
								}
							}
						}
					}
					... on ContentfulIndustryPage {
						sys {
							contentType {
								sys {
									id
								}
							}
						}
					}
				}
			}
		}

		allContentfulIndustryPage(sort: { fields: updatedAt, order: DESC }, limit: 1) {
			nodes {
				overviewHeading
				industryStatisticsHeading
				videosHeading
				industryResourcesHeading
				industryLinksHeading
				additionalNavLinks {
					url
					title
				}
			}
		}

		allContentfulResearchDevelopmentPage(sort: { fields: updatedAt, order: DESC }, limit: 1) {
			nodes {
				overviewHeading
				lepResearchProgramHeading
				researchProjectsHeading
				researchReportsHeading
				additionalNavLinks {
					url
					title
				}
			}
		}

		allContentfulWelfarePage(sort: { fields: updatedAt, order: DESC }, limit: 1) {
			nodes {
				overviewHeading
				researchProjectsHeading
				publicationsReportsHeading
				videosHeading
				additionalNavLinks {
					url
					title
				}
			}
		}

		allContentfulAboutPage(sort: { fields: updatedAt, order: DESC }, limit: 1) {
			nodes {
				overviewHeading
				chiefExecutiveOfficerHeading
				livecorpBoardOfDirectorsHeading
				corporateGovernanceHeading
				additionalNavLinks {
					url
					title
				}
			}
		}

		allContentfulMediaPage(sort: { fields: updatedAt, order: DESC }, limit: 1) {
			nodes {
				articlesHeading
				mediaReleasesHeading
				additionalNavLinks {
					url
					title
				}
			}
		}

		allContentfulProgramsAndServicesPage(sort: { fields: updatedAt, order: DESC }, limit: 1) {
			nodes {
				additionalNavLinks {
					url
					title
				}
			}
		}
	}
`;

interface IndustryPageHeadings {
	overviewHeading?: string | null;
	industryStatisticsHeading?: string | null;
	videosHeading?: string | null;
	industryResourcesHeading?: string | null;
	industryLinksHeading?: string | null;
	additionalNavLinks?: Maybe<ContentfulPage>[] | null;
}

interface ResearchDevelopmentHeadings {
	overviewHeading?: string | null;
	lepResearchProgramHeading?: string | null;
	researchProjectsHeading?: string | null;
	researchReportsHeading?: string | null;
	additionalNavLinks?: Maybe<ContentfulPage>[] | null;
}

interface WelfareHeadings {
	overviewHeading?: string | null;
	researchProjectsHeading?: string | null;
	publicationsReportsHeading?: string | null;
	videosHeading?: string | null;
	additionalNavLinks?: Maybe<ContentfulPage>[] | null;
}

interface AboutHeadings {
	overviewHeading?: string | null;
	chiefExecutiveOfficerHeading?: string | null;
	livecorpBoardOfDirectorsHeading?: string | null;
	corporateGovernanceHeading?: string | null;
	additionalNavLinks?: Maybe<ContentfulPage>[] | null;
}

interface MediaHeadings {
	articlesHeading?: string | null;
	mediaReleasesHeading?: string | null;
	additionalNavLinks?: Maybe<ContentfulPage>[] | null;
}

interface ServicesHeadings {
	additionalNavLinks?: Maybe<ContentfulPage>[] | null;
}

type AnyHeadings =
	| IndustryPageHeadings
	| ResearchDevelopmentHeadings
	| WelfareHeadings
	| AboutHeadings
	| MediaHeadings;

interface QueryResult {
	allContentfulFooter: {
		nodes: ContentfulFooter[];
	};
	allContentfulIndustryPage: {
		nodes: IndustryPageHeadings[];
	};
	allContentfulResearchDevelopmentPage: {
		nodes: ResearchDevelopmentHeadings[];
	};
	allContentfulWelfarePage: {
		nodes: WelfareHeadings[];
	};
	allContentfulAboutPage: {
		nodes: AboutHeadings[];
	};
	allContentfulMediaPage: {
		nodes: MediaHeadings[];
	};
	allContentfulProgramsAndServicesPage: {
		nodes: ServicesHeadings[];
	};
}

interface LinkToPageItem {
	sys?: {
		contentType?: {
			sys?: {
				id?: ContentfulTypes;
			};
		};
	};
	url?: string;
	title?: string;
}

type FooterCRLink = { label: string; slug: string };

/**
 * A map - contentful's field to hard-coded value in case the field was not filled.
 * key: field ID in Contentful
 * value: default hard-coded value
 * this is done for each main page
 */
const fieldToDefaultMap = {
	industry: {
		overviewHeading: defaultIndustryPageSections.overview,
		industryStatisticsHeading: defaultIndustryPageSections.industryStatistics,
		videosHeading: defaultIndustryPageSections.videos,
		industryResourcesHeading: defaultIndustryPageSections.industryResources,
		industryLinksHeading: defaultIndustryPageSections.industryLinks,
	},
	research: {
		overviewHeading: defaultResearchAndDevelopmentPageSections.overview,
		researchProjectsHeading: defaultResearchAndDevelopmentPageSections.researchProjects,
		lepResearchProgramHeading: defaultResearchAndDevelopmentPageSections.lepResearchProgram,
		researchReportsHeading: defaultResearchAndDevelopmentPageSections.reports,
	},
	welfare: {
		overviewHeading: defaultWelfarePageSections.overview,
		researchProjectsHeading: defaultWelfarePageSections.projects,
		publicationsReportsHeading: defaultWelfarePageSections.publications,
		videosHeading: defaultWelfarePageSections.videos,
	},
	about: {
		overviewHeading: defaultAboutPageSections.overview,
		chiefExecutiveOfficerHeading: defaultAboutPageSections.chiefExecutiveOfficer,
		livecorpBoardOfDirectorsHeading: defaultAboutPageSections.boardOfDirectors,
		corporateGovernanceHeading: defaultAboutPageSections.corporateGovernance,
	},
	media: {
		articlesHeading: defaultMediaPageSections.articles,
		mediaReleasesHeading: defaultMediaPageSections.media,
	},
	// These headings fields do not exist in Contentful for Programs and Services page. However, for consistency, we add them here.
	services: {
		supplyChainHeading: defaultProgramsAndServicesPageSections.supplyChain,
		marketAccessHeading: defaultProgramsAndServicesPageSections.marketAccess,
		trainingHeading: defaultProgramsAndServicesPageSections.training,
		inMarketServicesHeading: defaultProgramsAndServicesPageSections.inMarketServices,
	},
};

const getMainSections = <T extends AnyHeadings>(
	pageName: PageNames,
	node: T,
	map: { [field: string]: string }
): NavLink[] => {
	return Object.entries(map).map(([field, defaultVal]) => {
		const typedField = field as keyof T;
		const heading = (node[typedField] || defaultVal) as string;
		return {
			url: `/${LinkHelper.getPageBaseUrl(pageName)}#${LinkHelper.parseInternalLink(heading)}`,
			text: heading as string,
		};
	});
};

const getExtraSections = <T extends AnyHeadings>(node: T): NavLink[] => {
	return (
		node.additionalNavLinks
			?.filter((page) => page && page.url && page.title)
			.map((page) => {
				return {
					url: `/${page!.url!}`,
					text: page!.title!,
				};
			}) || []
	);
};

/**
 * returns an object which has the internal headings of the specified page
 * @param page the page to get the internal headings for
 */
const getLinkToPageSections = (page: string | null | undefined, data: QueryResult): NavLink[] => {
	let node = undefined as AnyHeadings | undefined;
	let map = undefined as { [field: string]: string } | undefined;
	switch (page) {
		case PageNames.Industry:
			[node] = data?.allContentfulIndustryPage?.nodes || [];
			map = fieldToDefaultMap.industry;
			break;
		case PageNames.Research:
			[node] = data?.allContentfulResearchDevelopmentPage?.nodes || [];
			map = fieldToDefaultMap.research;
			break;
		case PageNames.Services:
			[node] = data?.allContentfulProgramsAndServicesPage?.nodes || [];
			map = fieldToDefaultMap.services;
			break;
		case PageNames.Welfare:
			[node] = data?.allContentfulWelfarePage?.nodes || [];
			map = fieldToDefaultMap.welfare;
			break;
		case PageNames.Media:
			[node] = data?.allContentfulMediaPage?.nodes || [];
			map = fieldToDefaultMap.media;
			break;
		case PageNames.About:
			[node] = data?.allContentfulAboutPage?.nodes || [];
			map = fieldToDefaultMap.about;
			break;
		default:
			return [];
	}
	if (node && page) {
		const mainSections = getMainSections(page, node, map);
		const extraSections = getExtraSections(node);
		return [...mainSections, ...extraSections];
	} else {
		return [];
	}
};

const DefaultFooter = ({ data }: { data: QueryResult }) => {
	const [pageData] = data?.allContentfulFooter?.nodes || [];

	if (!pageData) {
		console.error('No page data for this Footer');
		return <div className={styles.pageError}>This section is under maintenance</div>;
	}

	const {
		contentful_id,
		contactDetails,
		pages,
		linkToYoutube,
		linkToTwitter,
		linkToLinkedIn,
		abn,
		linksToPages,
	} = pageData;

	const buildFooterSection = (
		page: string | null | undefined
	): React.ReactElement<typeof FooterSection> => {
		const linksObj = getLinkToPageSections(page, data);
		return (
			<FooterSection
				key={page || undefined}
				title={page}
				to={`/${LinkHelper.getPageBaseUrl(page)}`}
			>
				{linksObj.map((link) => {
					return (
						//Example <AnchorLink key='speciesStatistics' to='/industry/Species-Statistics'>Species Statistics</Link>
						<AnchorLink key={link.url} to={link.url}>
							{link.text}
						</AnchorLink>
					);
				})}
			</FooterSection>
		);
	};

	const copyRightSectionLinks = linksToPages
		? (linksToPages
				.map((linkToPage) => {
					const pageType = linkToPage?.sys?.contentType?.sys?.id;
					if (pageType) {
						let label: string | undefined | null = getPageName(
							pageType as ContentfulTypes | undefined
						);
						let slug = `/${LinkHelper.getPageBaseUrl(pageType)}`;
						if (pageType === ContentfulTypes.Page) {
							label = linkToPage?.title;
							slug = (linkToPage as any)?.url || '';
						}
						if (label && slug) {
							return { label, slug };
						}
					}
					return undefined;
				})
				.filter((l: FooterCRLink | undefined) => l) as FooterCRLink[]) // get rid of undefined
		: [];
	return (
		<Footer abn={abn} copyRightSectionLinks={copyRightSectionLinks}>
			{pages
				?.filter((page) => page)
				.map((page: string | null | undefined) => buildFooterSection(page))}
			<FooterSection
				key="contact"
				title="Contact"
				to={`/${LinkHelper.getPageBaseUrl(PageNames.ContactUs)}`}
				prioritiseOnSmallScreens
			>
				{contactDetails?.phone && (
					<div>
						<b>Phone: </b>
						<a href={`tel:${contactDetails.phone}`}>{contactDetails.phone}</a>
					</div>
				)}
				{contactDetails?.fax && (
					<div>
						<b>Fax: </b>
						<span>{contactDetails.fax}</span>
					</div>
				)}
				{contactDetails?.email && (
					<div>
						<b>Email: </b>
						<a href={`mailto:${contactDetails.email}`}>{contactDetails.email}</a>
					</div>
				)}
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					{linkToYoutube && (
						<a href={linkToYoutube}>
							<YouTubeIcon color="#43485A" />
						</a>
					)}
					{linkToTwitter && (
						<a href={linkToTwitter}>
							<TwitterIcon color="#43485A" />
						</a>
					)}
					{linkToLinkedIn && (
						<a href={linkToLinkedIn}>
							<LinkedInIcon color="#43485A" />
						</a>
					)}
				</div>
			</FooterSection>
		</Footer>
	);
};

const DefaultHeader = (props: StaticTopNavProps & { data: QueryResult }) => {
	const { data } = props;

	const pagesSections = {
		industry: getLinkToPageSections(PageNames.Industry, data),
		research: getLinkToPageSections(PageNames.Research, data),
		services: getLinkToPageSections(PageNames.Services, data),
		welfare: getLinkToPageSections(PageNames.Welfare, data),
		about: getLinkToPageSections(PageNames.About, data),
		media: getLinkToPageSections(PageNames.Media, data),
	};

	return (
		<StaticTopNav
			onLoginTabClicked={props.onLoginTabClicked}
			isLoggedIn={props.isLoggedIn}
			links={pagesSections}
		/>
	);
};

interface Props {
	showLogin?: boolean;
	onLoginSuccess?: (params?: any) => any;
	children?: ReactNode;
}

const QueryWrapper = (props: Props) => {
	const data = useStaticQuery<QueryResult>(queryQl);
	const { showLogin = false, onLoginSuccess, children } = props;
	const [showLoginPopup, setShowLoginPopup] = useState(showLogin);
	const loginManager = useLoginManager();

	useEffect(() => {
		setShowLoginPopup(showLogin);
	}, [showLogin]);

	const onLogin = (result: LoginResult) => {
		if (result.loggedIn) {
			onLoginSuccess && onLoginSuccess();
			setShowLoginPopup(false);
		}
	};

	return (
		<DefaultLayout
			header={
				<DefaultHeader
					onLoginTabClicked={() => setShowLoginPopup(true)}
					isLoggedIn={loginManager.isLoggedIn}
					data={data}
				/>
			}
			footer={<DefaultFooter data={data} />}
		>
			<>
				{showLoginPopup && (
					<LoginFormPopup onLogin={onLogin} onClose={() => setShowLoginPopup(false)} />
				)}
				{children}
			</>
		</DefaultLayout>
	);
};

export const DefaultLayoutTemplate: React.FC<Props> = (props: Props) => {
	return <QueryWrapper {...props} />;
};

export default () => <QueryWrapper />;
