import React, { CSSProperties } from 'react';
import { ImageSvg } from '../utils/customIcon';

interface Props {
	containerClass?: String;
	imgClass?: String;
	containerCss?: CSSProperties;
	imgCss?: CSSProperties;
}

export const AnimalWelfare = (props: Props) => {
	const svg = require('./animal-welfare.svg');
	return <ImageSvg {...props} svg={svg} />;
};

export const AnimalWelfareV2 = (props: Props) => {
	const svg = require('./animal-welfare-v2.svg');
	return <ImageSvg {...props} svg={svg} />;
};
