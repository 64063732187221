import styles from './styles.module.css';
import React, { CSSProperties } from 'react';

interface Props {
	color?: string;
	scale?: number;
}

export const YouTubeIcon = (props: Props) => {
	const { color, scale } = props;
	const fill = color ? color : 'currentColor';
	const width = 29 * (scale ? scale : 1);
	const height = 29 * (scale ? scale : 1);
	return (
		<span className={styles.iconWrapper}>
			<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 29 29">
				<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
					<g transform="translate(-1140 -186)">
						<g>
							<g transform="translate(1140 186)">
								<circle cx="14.5" cy="14.5" r="14.5" fill={fill}></circle>
								<path
									fill="#FFF"
									d="M20.123 9H8.877A2.877 2.877 0 006 11.877v6.015a2.877 2.877 0 002.877 2.877h11.246A2.877 2.877 0 0023 17.892v-6.015A2.877 2.877 0 0020.123 9zm-4.904 7.192L12.67 17.5v-5.23l5.1 2.615-2.55 1.307z"
								></path>
							</g>
						</g>
					</g>
				</g>
			</svg>
		</span>
	);
};

export const TwitterIcon = (props: Props) => {
	const { color, scale } = props;
	const fill = color ? color : 'currentColor';
	const width = 29 * (scale ? scale : 1);
	const height = 29 * (scale ? scale : 1);
	return (
		<span className={styles.iconWrapper}>
			<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 29 29">
				<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
					<g transform="translate(-1185 -186)">
						<g>
							<g transform="translate(1185 186)">
								<circle cx="14.5" cy="14.5" r="14.5" fill={fill}></circle>
								<path
									fill="#FFF"
									fillRule="nonzero"
									d="M23 9.647c-.637.265-1.302.478-1.992.558a3.512 3.512 0 001.54-1.94 6.921 6.921 0 01-2.204.85A3.553 3.553 0 0017.767 8a3.475 3.475 0 00-3.48 3.48c0 .265.027.53.08.797a9.827 9.827 0 01-7.172-3.64 3.532 3.532 0 00-.478 1.754c0 1.222.611 2.284 1.54 2.895a3.705 3.705 0 01-1.566-.425v.053c0 1.7 1.195 3.108 2.789 3.427-.293.08-.611.132-.93.132-.213 0-.452-.026-.664-.053a3.495 3.495 0 003.267 2.417 7.056 7.056 0 01-4.33 1.488c-.292 0-.557-.027-.823-.053a10.12 10.12 0 005.34 1.54c6.427 0 9.933-5.312 9.933-9.934v-.451A7.335 7.335 0 0023 9.647z"
								></path>
							</g>
						</g>
					</g>
				</g>
			</svg>
		</span>
	);
};

export const LinkedInIcon = (props: Props) => {
	const { color, scale } = props;
	const fill = color ? color : 'currentColor';
	const width = 29 * (scale ? scale : 1);
	const height = 29 * (scale ? scale : 1);
	return (
		<span className={styles.iconWrapper}>
			<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 29 29">
				<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
					<g transform="translate(-1230 -186)">
						<g>
							<g transform="translate(1230 186)">
								<circle cx="14.5" cy="14.5" r="14.5" fill={fill}></circle>
								<path
									fill="#FFF"
									fillRule="nonzero"
									d="M10.48 20.52H7.596v-9.474h2.966v9.475h-.082zM8.997 9.728c-.906 0-1.73-.741-1.73-1.73 0-.906.741-1.73 1.73-1.73.906 0 1.73.741 1.73 1.73s-.741 1.73-1.73 1.73zM21.52 20.521h-2.966v-4.614c0-1.071 0-2.472-1.483-2.472-1.566 0-1.73 1.153-1.73 2.39v4.696h-2.967v-9.475h2.801v1.318c.412-.742 1.319-1.483 2.802-1.483 2.966 0 3.543 1.977 3.543 4.531v5.109z"
								></path>
							</g>
						</g>
					</g>
				</g>
			</svg>
		</span>
	);
};
