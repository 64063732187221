import styles from './styles.module.css';
import React, { CSSProperties } from 'react';

interface Props {
	state: 'opened' | 'closed';
	color?: string;
	scale?: number;
	onClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
	style?: CSSProperties;
}

export const NavDrawerIcon = (props: Props) => {
	const { state, color, scale, onClick, style } = props;
	const fill = color ? color : 'currentColor';
	const width = state === 'closed' ? 27 : 22;
	const dimension = {
		width: width * (scale ? scale : 1),
		height: 22 * (scale ? scale : 1),
	};
	const svgClosed = (
		<span className={styles.wrapper} style={style}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={dimension.width}
				height={dimension.height}
				viewBox="0 0 27 22"
				onClick={onClick}
			>
				<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
					<g fill={fill} transform="translate(-361 -27)">
						<path d="M363.025 27c-1.118 0-2.025.896-2.025 2s.907 2 2.025 2h22.95c1.118 0 2.025-.896 2.025-2s-.907-2-2.025-2h-22.95zm0 9c-1.118 0-2.025.896-2.025 2s.907 2 2.025 2h22.95c1.118 0 2.025-.896 2.025-2s-.907-2-2.025-2h-22.95zm0 9c-1.118 0-2.025.896-2.025 2s.907 2 2.025 2h22.95c1.118 0 2.025-.896 2.025-2s-.907-2-2.025-2h-22.95z"></path>
					</g>
				</g>
			</svg>
		</span>
	);

	const svgOpen = (
		<span className={styles.wrapper} style={style}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={dimension.width}
				height={dimension.height}
				viewBox="0 0 21 22"
				onClick={onClick}
			>
				<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
					<g fill={fill} transform="translate(-364 -27)">
						<path
							d="M374.5 24.5c1.104 0 2 .907 2 2.025V36h9.475c1.067 0 1.942.816 2.02 1.85L388 38c0 1.104-.907 2-2.025 2H376.5v9.475a2.016 2.016 0 01-1.85 2.02l-.15.005c-1.104 0-2-.907-2-2.025V40h-9.475a2.016 2.016 0 01-2.02-1.85L361 38c0-1.104.907-2 2.025-2h9.475v-9.475c0-1.067.816-1.942 1.85-2.02z"
							transform="rotate(45 374.5 38)"
						></path>
					</g>
				</g>
			</svg>
		</span>
	);

	return state === 'closed' ? svgClosed : svgOpen;
};
