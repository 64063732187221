import React from 'react';

import styles from './styles.module.css';

interface Props {
	color?: string;
	scale?: number;
}

export const ArrowCircle = (props: Props) => {
	const { color, scale } = props;
	const width = 54 * (scale ? scale : 1);
	const height = 54 * (scale ? scale : 1);
	const fill = color ? color : 'currentColor';
	return (
		<span className={styles.iconWrapper}>
			<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 54 54">
				<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
					<g transform="translate(-609 -243)">
						<g transform="translate(609 243)">
							<rect width="54" height="54" x="0" y="0" fill={fill} rx="27"></rect>
							<path
								fill="#FFF"
								d="M38.447 26.195l-7.71-7.665a1.866 1.866 0 00-2.605 0 1.84 1.84 0 000 2.59l4.552 4.552H15.842A1.826 1.826 0 0014 27.503c0 1.02.816 1.831 1.842 1.831h16.842l-4.553 4.527a1.84 1.84 0 000 2.59c.368.365.842.549 1.316.549.474 0 .948-.183 1.316-.55l7.684-7.639c.342-.34.553-.811.553-1.308 0-.496-.184-.968-.553-1.308z"
							></path>
						</g>
					</g>
				</g>
			</svg>
		</span>
	);
};
