import styles from './styles.module.css';
import classNames from 'classnames';
import React, { CSSProperties, ReactNode } from 'react';

interface Props {
	type: 'text' | 'textArea' | 'number' | 'password';
	placeHolder?: string | null;
	name?: string;
	required?: boolean;
	ref?: any;
	iconLeft?: ReactNode;
	iconRight?: ReactNode;
	errorMessage?: string;
	outerContainerStyle?: CSSProperties;
	containerStyle?: CSSProperties;
	errorMessageStyle?: CSSProperties;
	inputClassName?: string;
	numberMin?: number;
	numberMax?: number;
	disabled?: boolean;
	autoComplete?: string;
}

const textInput = (props: Props, ref: any) => {
	const {
		placeHolder,
		iconLeft,
		iconRight,
		containerStyle,
		name,
		errorMessage,
		errorMessageStyle,
		outerContainerStyle,
		inputClassName,
		type,
		disabled,
		autoComplete,
		required,
	} = props;
	const leftIconJsx = iconLeft ? <span className={styles.iconLeft}>{iconLeft}</span> : undefined;
	const rightIconJsx = iconRight ? (
		<span className={styles.iconRight}>{iconRight}</span>
	) : undefined;
	const inputClass = classNames(
		styles.defaultInput,
		{ [styles.withIconLeft]: iconLeft },
		{ [styles.withIconRight]: iconRight },
		inputClassName
	);

	const ph = placeHolder || '';
	const inputPlaceHolder = ph + (ph && required ? ' *' : '');

	return (
		<div className={styles.outerContainer} style={outerContainerStyle}>
			<div className={styles.defaultContainer} style={containerStyle}>
				{leftIconJsx}
				<input
					name={name}
					type={type}
					className={inputClass}
					placeholder={inputPlaceHolder}
					alt={inputPlaceHolder}
					ref={ref}
					disabled={disabled}
					autoComplete={autoComplete}
				/>
				{rightIconJsx}
			</div>
			<div className={styles.errorMessage} style={errorMessageStyle}>
				{errorMessage}
			</div>
		</div>
	);
};

const numberInput = (props: Props, ref: any) => {
	const {
		placeHolder,
		iconLeft,
		iconRight,
		containerStyle,
		name,
		errorMessage,
		errorMessageStyle,
		outerContainerStyle,
		numberMin,
		numberMax,
		inputClassName,
		disabled,
		autoComplete,
		required,
	} = props;
	const leftIconJsx = iconLeft ? <span className={styles.iconLeft}>{iconLeft}</span> : undefined;
	const rightIconJsx = iconRight ? (
		<span className={styles.iconRight}>{iconRight}</span>
	) : undefined;
	const inputClass = classNames([
		styles.defaultInput,
		{ [styles.withIconLeft]: iconLeft },
		{ [styles.withIconRight]: iconRight },
		inputClassName,
	]);

	const ph = placeHolder || '';
	const inputPlaceHolder = ph + (ph && required ? ' *' : '');

	return (
		<div className={styles.outerContainer} style={outerContainerStyle}>
			<div className={styles.defaultContainer} style={containerStyle}>
				{leftIconJsx}
				<input
					name={name}
					type="number"
					className={inputClass}
					placeholder={inputPlaceHolder}
					alt={inputPlaceHolder}
					ref={ref}
					min={numberMin}
					max={numberMax}
					disabled={disabled}
					autoComplete={autoComplete}
				/>
				{rightIconJsx}
			</div>
			<div className={styles.errorMessage} style={errorMessageStyle}>
				{errorMessage}
			</div>
		</div>
	);
};

const textAreaInput = (props: Props, ref: any) => {
	const {
		placeHolder,
		containerStyle,
		name,
		errorMessage,
		errorMessageStyle,
		outerContainerStyle,
		inputClassName,
		disabled,
		autoComplete,
		required,
	} = props;

	const outerContainerClass = classNames([styles.outerContainer, styles.textAreaContainer]);

	const textAreaClass = classNames([styles.textArea, inputClassName]);

	const ph = placeHolder || '';
	const inputPlaceHolder = ph + (ph && required ? ' *' : '');

	return (
		<div className={outerContainerClass} style={outerContainerStyle}>
			<div className={styles.defaultContainer} style={containerStyle}>
				<textarea
					name={name}
					className={textAreaClass}
					placeholder={inputPlaceHolder}
					ref={ref}
					disabled={disabled}
					autoComplete={autoComplete}
				></textarea>
			</div>
			<div className={styles.errorMessage} style={errorMessageStyle}>
				{errorMessage}
			</div>
		</div>
	);
};

export const CustomInput = React.forwardRef((props: Props, ref: any) => {
	switch (props.type) {
		case 'text':
		case 'password':
			return textInput(props, ref);
		case 'textArea':
			return textAreaInput(props, ref);
		case 'number':
			return numberInput(props, ref);
		default:
			return null;
	}
});
