import { Maybe, ContentfulCollapsiblePanelSection } from 'src/graphql-types';
import moment from 'moment';

export enum TableType {
	Aavs = 'AAVs',
	Stockpersons = 'Stockpersons',
}

/**
 * These options should be identical to the options shown in Contentful whenever we get users to choose a page.
 */
export enum PageNames {
	Home = 'Home',
	Industry = 'Industry',
	Research = 'Research & Development',
	Services = 'Programs & Services',
	Welfare = 'Animal Welfare',
	Media = 'Media',
	About = 'About',
	ContactUs = 'Contact Us',
	Membership = 'Membership',
	Search = 'Search',
	RequestSignUp = 'Request Sign Up',
}

/**
 * These options should be identical to the options shown in Contentful checkboxes shown on each for the Resources to indicate if a resource is relevant to a page.
 */
export enum RelevantPages {
	Welfare = 'Animal Welfare',
	Research = 'Research & Development',
	PSSupplyChain = 'Prog & Serv - Supply Chain',
	PSTraining = 'Prog & Serv - Training',
	MediaArticles = 'Media - Articles Section',
	MediaReleases = 'Media - Media Releases Section',
}

/**
 * types used at frontend for Contentful Resources
 */
export enum ContentfulTypes {
	Article = 'ContentfulArticleResource',
	Project = 'ContentfulProjectResource',
	Publication = 'ContentfulPublicationResource',
	Video = 'ContentfulVideoResource',
	Report = 'ContentfulReportResource',
	/*
		Contentful{contentType} is what we receive from querying Contentful via the Gatsby plugin.
		{contentType} (without 'Contentful' prepended) is what we receive from the backend.
		TODO: modify backend so it reflects the same format used by Contentful.
	*/
	ContentfulHomePage = 'ContentfulHomePage',
	HomePage = 'homePage',

	ContentfulIndustryPage = 'ContentfulIndustryPage',
	IndustryPage = 'industryPage',
	ContentfulResearchAndDevelopmentPage = 'ContentfulResearchDevelopmentPage',
	ResearchAndDevelopmentPage = 'researchDevelopmentPage',

	ContentfulProgramsAndServicesPage = 'ContentfulProgramsAndServicesPage',
	ProgramsAndServicesPage = 'programsAndServicesPage',

	ContentfulWelfarePage = 'ContentfulWelfarePage',
	WelfarePage = 'welfarePage',

	ContentfulMediaPage = 'ContentfulMediaPage',
	MediaPage = 'mediaPage',

	ContentfulAboutPage = 'ContentfulAboutPage',
	AboutPage = 'aboutPage',

	ContentfulContactUsPage = 'ContactUsPage',
	ContactUsPage = 'contactUsPage',

	FormTab = 'ContentfulFormTab',

	ContentfulPage = 'ContentfulPage',
	Page = 'page',

	ContentfulCollapsiblePanel = 'ContentfulCollapsiblePanelSection',
	CollapsiblePanel = 'collapsiblePanelSection',
}
/**
 * This enum should be identical to 'SupportedContentTypes' enum in backend/src/graphql/search/index/ts
 */
export enum SupportedSearchContentTypes {
	ArticleResource = 'articleResource',
	ProjectResource = 'projectResource',
	PublicationResource = 'publicationResource',
	ReportResource = 'report', // typo - Contentful ID is report instead of reportResource. It's too late to change now
	VideoResource = 'videoResource',
	HomePage = 'homePage',
	IndustryPage = 'industryPage',
	ResearchPage = 'researchDevelopmentPage',
	ServicesPage = 'programsAndServicesPage',
	WelfarePage = 'welfarePage',
	AboutPage = 'aboutPage',
	MediaPage = 'mediaPage',
	Page = 'page', // pages made by content creators in Contentful via the Page content-type
	CollapsiblePanel = 'collapsiblePanelSection',
	FormTab = 'contactUsTab', // Originally used only for contactUs page, but now used anywhere a form can be attached,
	LinkWithDescription = 'linkWithDescription',
	IndustryStatisticsPanel = 'industryStatisticsPanel',
}

export const isMainPage = (theType: ContentfulTypes | BackEndContentTypes) => {
	return [
		ContentfulTypes.HomePage,
		ContentfulTypes.IndustryPage,
		ContentfulTypes.ResearchAndDevelopmentPage,
		ContentfulTypes.ProgramsAndServicesPage,
		ContentfulTypes.WelfarePage,
		ContentfulTypes.MediaPage,
		ContentfulTypes.AboutPage,
		ContentfulTypes.ContactUsPage,
	].includes(theType as any);
};

export const getPageName = (contentfulType: ContentfulTypes | undefined) => {
	switch (contentfulType) {
		case ContentfulTypes.HomePage:
		case ContentfulTypes.ContentfulHomePage:
			return PageNames.Home;
		case ContentfulTypes.IndustryPage:
		case ContentfulTypes.ContentfulIndustryPage:
			return PageNames.Industry;
		case ContentfulTypes.ResearchAndDevelopmentPage:
		case ContentfulTypes.ContentfulResearchAndDevelopmentPage:
			return PageNames.Research;
		case ContentfulTypes.ProgramsAndServicesPage:
		case ContentfulTypes.ContentfulProgramsAndServicesPage:
			return PageNames.Services;
		case ContentfulTypes.WelfarePage:
		case ContentfulTypes.ContentfulWelfarePage:
			return PageNames.Welfare;
		case ContentfulTypes.MediaPage:
		case ContentfulTypes.ContentfulMediaPage:
			return PageNames.Media;
		case ContentfulTypes.AboutPage:
		case ContentfulTypes.ContentfulAboutPage:
			return PageNames.About;
		case ContentfulTypes.ContactUsPage:
		case ContentfulTypes.ContentfulContactUsPage:
			return PageNames.ContactUs;
		default:
			return '';
	}
};

// returned types from the backend
// TODO: modify types format, prepend 'Contentful', so it reflects the same format used by Contentful.
export enum BackEndContentTypes {
	Article = 'articleResource',
	Project = 'projectResource',
	Publication = 'publicationResource',
	Video = 'videoResource',
	Report = 'report',
	DynamicForm = 'contactUsTab',
}

export enum FilterContentTypeItems {
	Project = 'Research Project',
	Report = 'Research Report',
	Publication = 'Publication',
	Article = 'Article',
	Corporate = 'Corporate',
	Magazine = 'Magazine',
	Video = 'Video',
	Other = 'Other',
}

/**
 * These enum values must be identical to Contentful's available options on the 'accessibleBy' field of each content type that has this field (Publication, Report, Video, Project, (any other resource), Collapsible Panel)
 */
export enum AccessibleByValues {
	Public = 'Public',
	Members = 'Members',
	Industry = 'Industry',
	Aavs = 'AAVs',
	Stockies = 'Stockies',
	Administrators = 'LiveCorp Administrators',
	RPAndVesselOperators = 'RP & Vessel Operators',
}

/**
 * When user is not authenticated
 */
export const SERVER_RESPONSE_NO_ACCESS = 'GraphQL error: You do not have access to this resource';

/**
 * When queried item is not accessible by user due to the user type not being allowed to view this item.
 */
export const SERVER_RESPONSE_NO_ACCESS_TO_USER_TYPE =
	'GraphQL error: Your User Type does not have access to this resource';

// this was originally inside /pages/industry, /pages/about, ... but it generated a weird runtime error on production which led to the page-data not being delivered to the page on page-refresh. Perhaps due to cross-reference between two components referencing each-other -> layout and a page
export const defaultIndustryPageSections = {
	overview: 'Overview',
	industryStatistics: 'Species Statistics',
	videos: 'Videos',
	industryResources: 'Industry Resources',
	industryLinks: 'Industry Links',
};
export const defaultAboutPageSections = {
	overview: 'Introduction',
	chiefExecutiveOfficer: 'Chief Executive Officer',
	boardOfDirectors: 'LiveCorp Board of Directors',
	corporateGovernance: 'LiveCorp Governance',
};
export const defaultMediaPageSections = {
	articles: 'Articles',
	media: 'Media Releases',
};
export const defaultProgramsAndServicesPageSections = {
	supplyChain: 'Supply Chain',
	marketAccess: 'Market Access',
	training: 'Training',
	inMarketServices: 'In-Market Services',
};
export const defaultServicesPageTabsSections = {
	supplyChain: {
		researchProjectsHeading: 'Research Projects',
		publicationsAndReportsHeading: 'Publications & Reports',
	},
	marketAccess: {
		tradeEnquiriesHeading: 'Trade Enquiries',
	},
	training: {
		topSection: 'Top Section',
		bottomSection: 'Bottom Section',
		otherMaterialsHeading: 'Other Training Materials',
	},
	inMarketServices: {
		contactUsHeading: 'Contact Us',
	},
};
export const defaultResearchAndDevelopmentPageSections = {
	overview: 'Overview',
	researchProjects: 'Research Projects',
	lepResearchProgram: 'LEP Research Program',
	reports: 'Reports',
};
export const defaultWelfarePageSections = {
	overview: 'Overview',
	projects: 'Research Projects',
	publications: 'Publications & Reports',
	videos: 'Videos',
};

export enum PrivateContentTypes {
	CollapsiblePanel = 'CollapsiblePanel',
}

export interface PrivateCollapsiblePanelSection {
	contentType: PrivateContentTypes.CollapsiblePanel;
	contentful_id: string;
	title?: string;
	showOpened?: boolean;
}

export const getCollapsiblePanelContent = (
	collapsiblePanel: Maybe<ContentfulCollapsiblePanelSection>
): ContentfulCollapsiblePanelSection | PrivateCollapsiblePanelSection | undefined => {
	if (collapsiblePanel && collapsiblePanel.contentful_id) {
		const isPublic =
			!collapsiblePanel.accessibleBy ||
			(collapsiblePanel.accessibleBy &&
				collapsiblePanel.accessibleBy.length &&
				collapsiblePanel.accessibleBy.find((ab) => ab === AccessibleByValues.Public));

		if (isPublic) {
			return collapsiblePanel;
		}

		return {
			contentType: PrivateContentTypes.CollapsiblePanel,
			contentful_id: collapsiblePanel.contentful_id,
			showOpened: collapsiblePanel.showOpened || false,
		};
	}

	return undefined;
};

interface PublicPrivateResource {
	accessibleBy?: AccessibleByValues[] | null;
}

export const filterPublicResources = (resources: PublicPrivateResource[] | null | undefined) => {
	return resources?.filter(
		(resource) =>
			resource &&
			(!resource.accessibleBy ||
				(resource.accessibleBy &&
					resource.accessibleBy.length &&
					resource.accessibleBy.find((ab) => ab === AccessibleByValues.Public)))
	);
};

export const formatDate = (date: string | undefined | null) => {
	if (!Boolean(date)) {
		return date;
	}
	try {
		const momentDate = moment(date!);
		return momentDate.isValid() ? momentDate.format('DD/MM/YYYY') : date;
	} catch (e) {
		return date;
	}
};

/**
 * Gets a High Definition image from Contentful.
 * @param url Contentful's url of the image
 */
export const getHrImageUrl = (url?: string | null): string | undefined => {
	if (!url) {
		return undefined;
	}

	let width = undefined;
	if (typeof window !== 'undefined') {
		width = window.innerWidth;
	}

	return `${url}?fm=jpg&fl=progressive${width ? `&w=${width}` : ''}`;
};
