import React from 'react';

interface Props {
	color?: string;
	scale?: number;
}

export const CloseIcon = (props: Props) => {
	const { color, scale } = props;
	const fill = color ? color : 'currentColor';
	const width = 33 * (scale ? scale : 1);
	const height = 33 * (scale ? scale : 1);
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 33 33">
			<g fill={fill} fillRule="evenodd" stroke="none" strokeWidth="1">
				<path
					fill={fill}
					d="M18.444 16.5l4.378 4.376a1.377 1.377 0 01-1.945 1.945L16.5 18.445l-4.376 4.378a1.377 1.377 0 01-1.945-1.945l4.377-4.377-4.378-4.376a1.377 1.377 0 011.945-1.945l4.377 4.377 4.376-4.378a1.377 1.377 0 011.945 1.945L18.445 16.5zM16.5 33C7.388 33 0 25.612 0 16.5S7.388 0 16.5 0 33 7.388 33 16.5 25.612 33 16.5 33zm0-2.75c7.594 0 13.75-6.156 13.75-13.75S24.094 2.75 16.5 2.75 2.75 8.906 2.75 16.5 8.906 30.25 16.5 30.25z"
				></path>
			</g>
		</svg>
	);
};
