import styles from './styles.module.css';
import React from 'react';

interface Props {
	color?: string;
	scale?: number;
}

export const SearchIcon = (props: Props) => {
	const { color, scale } = props;
	const fill = color ? color : 'currentColor';
	const width = 23 * (scale ? scale : 1);
	const height = 23 * (scale ? scale : 1);
	return (
		<span className={styles.iconWrapper}>
			<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 23 23">
				<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
					<g fill={fill} transform="translate(-289 -27)">
						<path d="M298.54 27c-5.247 0-9.54 4.284-9.54 9.531 0 5.247 4.292 9.541 9.54 9.541a9.462 9.462 0 005.426-1.72l5.157 5.148a1.674 1.674 0 002.385 0 1.68 1.68 0 000-2.375l-5.157-5.158a9.452 9.452 0 001.72-5.436c-.002-5.248-4.294-9.531-9.531-9.531zm0 3.359a6.144 6.144 0 016.161 6.171 6.143 6.143 0 01-6.161 6.172 6.145 6.145 0 01-6.17-6.172 6.145 6.145 0 016.17-6.171z"></path>
					</g>
				</g>
			</svg>
		</span>
	);
};
