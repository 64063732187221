// ------------------------------------------------------------------
// NOTE: This file is overwritten at build time by code in
// gatsby-node-create-pages.ts. Do not edit by hand.
// ------------------------------------------------------------------
export const collapsiblePanelMap: { [contentful_id: string]: string } = {
	"7E9kzGHx39Y4fA8x1bZAXg": "/about#Overview-LiveCorp-funding",
	"70i2YNfaJt7Dd0C9gCQ4gV": "/about#Overview-Dairy-cattle-export-levy",
	"4wWhjtQTrkk5aYuqDdY1um": "/about#Overview-Frequently-asked-questions-about-LiveCorp",
	"61MFgkCpovDdsnBDv5f5EL": "/about#Overview-LiveCorp-membership",
	"6Z6mAcqgN3Zwvi5DMlWOj0": "/about#Overview-Industry-collaboration",
	"7ouGemfTORxH9Dpwxye3d9": "/industry#Overview-How-livestock-are-exported",
	"3lFWMKGBgEvQPjO2DtFGCE": "/industry#Overview-Types-of-livestock-exported",
	"6y8BvklRYEacZEZfp67HcE": "/industry#Overview-Regulatory-framework",
	"3mzwk69Q4EQKGlzbSS0wRD": "/industry#Overview-Frequently-asked-questions-for-those-interested-in-exporting-livestock",
	"7GNBpuKg6aoku9W5zGiX9X": "/industry#Industry-links-LIVEXchange",
	"cqNhQZF2TxZnbn89uUEOi": "/industry#Industry-links-Industry-bodies",
	"7ttO8w2tke656pSgZ099xn": "/industry#Industry-links-Australian-government",
	"3lW6G4BwDR18CyOxSXSgf9": "/industry#Industry-links-Regulatory-information",
	"6i0bovKVOOi39e2wnLXSpm": "/industry#Industry-links-LIVEXCollect",
	"7CtliZ73QdbYwGtRe9hyOr": "/industry#Industry-links-Jobs-board",
	"20BfOOMRY6yQKocrxGjrUt": "/industry#Industry-links-Livestock-export-representative-bodies",
	"42eNsmvAnD688ZOJplDFiG": "/industry#Industry-links-Breeding-livestock-committee",
	"3z8MA3IedEp9Zgp6NAbIvt": "/programsAndServices#Training-LiveCorp-Accredited-Stockpersons-List-of-current-LiveCorp-Accredited-Stockpersons",
	"5BEl3rZnDRIq7xRoRSSrbZ": "/industry#Industry-links-LiveCorp-submissions",
	"01NAVT8IQjbtfkCxvtMC5L": "/programsAndServices#Market-Access-Trade-protocols",
	"2aC19YAHsKp3wDVGWT6FsW": "/programsAndServices#Market-Access-Protocol-committee",
	"655ztZ2P9brNltw2uUxRN3": "/programsAndServices#Market-Access-Protocol-committee-contact-details",
	"2xRAiEbTahYmD9WpWTVylU": "/programsAndServices#Market-Access-Priority-markets",
	"15hyn5HJ41OcVIN2H7g3lT": "/programsAndServices#Market-Access-National-Arbovirus-Monitoring-Program",
	"5GiMdSPPJwouTaZiQ8TR2J": "/programsAndServices#In-Market-Services-Asia-Pacific",
	"5Abtlnf4OtbcA4vjyoSOxD": "/programsAndServices#In-Market-Services-Indonesia",
	"4ABsv762r54u2Isbgbx3BR": "/programsAndServices#In-Market-Services-Europe-the-Middle-East-and-North-Africa-EMENA",
	"3kUMWDJlT239Yl2KaPsSs9": "/programsAndServices#In-Market-Services-Livestock-export-collaborative-funding-program",
	"7cPKgaPKZw8hsKxEkQYho1": "/programsAndServices#Training-LiveCorp-Accredited-Stockpersons-Accreditation-pathway",
	"3VLDATSeWk8y8XnCbabgyg": "/programsAndServices#Training-LiveCorp-Accredited-Stockpersons-Stockpersons-online-training-platform",
	"6dom5oWyVg2T6z7sMDtUuS": "/programsAndServices#Training-LiveCorp-Accredited-Stockpersons-Frequently-asked-questions-about-the-accreditation-program",
	"3Whm0RowK80NTT6KAFV1WU": "/programsAndServices#Training-Shipboard-Stockperson-Training-Course-Next-course",
	"4QIt1LidWbP9DTJXCK8twA": "/programsAndServices#Training-Shipboard-Stockperson-Training-Course-Frequently-asked-questions-about-the-course",
	"6HMI91BeajahyPpxpTc9YT": "/researchAndDevelopment#LEP-research-program-How-it-works",
	"5R8KxKUsutTaegDRGH70Lk": "/researchAndDevelopment#LEP-research-program-RDE-Blueprint",
	"7HPDxwN35oZyx4XaJHCguU": "/researchAndDevelopment#LEP-research-program-How-it-is-managed",
	"5k6LOWQBtCH8702wJy8dFq": "/researchAndDevelopment#LEP-research-program-Management-committee-details",
	"3gDXEeHOnuVW1E67xfWRat": "/researchAndDevelopment#LEP-research-program-Livestock-Export-Research-and-Development-Advisory-Committee-details",
	"6xK2XyHJwiZSsgjrrk9aAN": "/researchAndDevelopment#LEP-research-program-Current-research-tenders",
	"27lbwHrq7zpwNB4pfU32vE": "/researchAndDevelopment#LEP-research-program-National-research-frameworks",
	"2uRMsrojrVvGIekjsuJdeh": "/welfare#Overview-Improvements-on-board-ships",
	"4vzAdDT1EM8rXXEVCmVQyT": "/welfare#Overview-Live-export-ship-tour",
	"2Vu45oLqNV3xl1zY8YNw1W": "/welfare#Overview-Improvements-in-our-markets",
	"4uJckxs0622QNfPrjBvspF": "/welfare#Overview-Frequently-asked-questions-about-welfare"
}