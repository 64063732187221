import styles from './styles.module.css';
import React, { CSSProperties } from 'react';

interface Props {
	color?: string;
	scale?: number;
	style?: CSSProperties;
}

export const DownloadIcon = (props: Props) => {
	const { color, scale, style } = props;
	const fill = color ? color : 'currentColor';
	const width = 19 * (scale ? scale : 1);
	const height = 18 * (scale ? scale : 1);
	return (
		<span className={styles.iconWrapper} style={style}>
			<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 19 18">
				<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
					<g fill={fill} transform="translate(-106 -1401)">
						<g transform="translate(93 1281)">
							<g transform="translate(13 73)">
								<g>
									<g transform="translate(.241 47)">
										<path d="M1.2 15.5h15.6c.664 0 1.2.663 1.2 1.2a1.2 1.2 0 01-1.2 1.2H1.2c-.663 0-1.2-.662-1.2-1.2a1.2 1.2 0 011.2-1.2z"></path>
										<path d="M5.682 7.438a1.28 1.28 0 00-1.808 0c-.5.497-.5 1.304 0 1.802l4.523 4.51a.855.855 0 001.206 0l4.523-4.51c.499-.498.498-1.305 0-1.802a1.28 1.28 0 00-1.808 0l-2.052 2.046v-8.21C10.266.571 9.694 0 8.988 0 8.282 0 7.71.57 7.71 1.274v8.187L5.682 7.438z"></path>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
			</svg>
		</span>
	);
};
